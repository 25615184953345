
export const languageKO = {
    open_box:'기프트 박스 열기',
    intro_text:`안녕하세요, 모엣&샹동 기프트샵에 오신 것을 환영합니다!<br>맞춤 제작 경험 전반에 걸쳐 고객님을 안내해 드리겠습니다.`,
    start_exp: "체험 시작",
    customise:"맞춤 제작",
    customise_brut:"맞춤 제작",
    customise_rose:"맞춤 제작",
    bot_start:`주저하지 마시고 벨을 눌러 불러주세요. 기꺼이 안내해 드리겠습니다.`,
    back:'뒤로',
    customise_title:'기프트 박스 맞춤 제작',
    customise_content:`Please enter the first name, date, year or city of your choice in the field below. (14 characters maximum)
    Customised products cannot be exchanged or refunded.`,
    mantra_content: `다음 메시지 중 하나를 선택하세요. 맞춤 제작된 모든 제품은 교환 및 환불이 불가능합니다.`,
    character_left:'14 characters left',
    customise_placeholder:'Enter your customisation here',
    confirm:'확인',
    ring:"벨을 누르기",
    buy:`구입하기`,
    box_title:`맞춤형 기프트 박스`,
    composition:`아황산염 함유. 알코올 도수: 12°`,
    brut_name:`모엣&샹동 브뤼 임페리얼 샴페인`,
    brut_description:`1869년부터 하우스를 상징하는 모엣 임페리얼은 샴페인의 궁극적 표현입니다.`,
    rose_name:`Moët & Chandon Rosé Impérial Champagne`,
    rose_description:`Rosé Impérial is the most spontaneous expression of the Moët & Chandon style.`,
    again:`다시 시작하기`,
    landing_page:`Specially Yours 페이지 보기`
}

export const messagesKO=[
    {id:0,type:"text",content:["안녕하세요, 모엣&샹동 기프트샵에 오신 것을 환영합니다.", "무엇을 도와드릴까요?"],next:1},
    {id:1,type:"menu",content:[
         "기프트 박스를 맞춤 제작하고 싶습니다",
         "이 기프트 박스에 대해 자세히 알고 싶습니다.",
         "모엣&샹동 스타일에 대해 말해주세요.",
         "모엣 임페리얼 테이스팅 노트는 무엇입니까?",
         "모엣 임페리얼과 잘 어울리는 음식은 무엇입니까?",
        ],
         choices:[4,6,8,14,16]},
    {id:2,type:"text",content:["With pleasure ! Do you want to choose: "],next:3},  
    {id:3,type:"menu",content:["Moët Brut Impérial","Moët Rosé Impérial"],choices:[22,23]},
    {id:4,type:"text",content:["↓ 아래에서 메시지를 선택하세요."],next:5},  
    {id:5,type:"menu",content:[
         "CHEERS TO YOU",
         "HAPPY BIRTHDAY TO YOU",
         "SPECIALLY YOURS",
         "마음을 담아",
         "새로운 도약을 위해",
         "특별한 당신에게",
        ],
         choices:[43,44,37,49,50,51]},
    {id:6,type:"img",content:['img_perso.jpeg'],next:7},  
    {id:7,type:"text",content:[`모엣&샹동이 맞춤 제작 서비스를 선보입니다 리미티드 에디션 기프트 박스에 원하는 이름, 날짜, 연도 또는 도시를 새길 수 있는 서비스입니다. 하우스를 상징하는 모엣&샹동 브뤼 임페리얼 또는 모엣&샹동 로제 임페리얼을 깜짝 선물하려는 샴페인 애호가들의 마음을 사로잡을 제안입니다! 주문을 확정할 때 입력한 맞춤 설정이 올바른지 확인하세요.  맞춤 제작된 모든 제품은 교환 및 환불이 불가능합니다.`,`다른 것을 원하십니까 ?`],next:1}, 
    {id:8,type:"text",content:[`모엣&샹동 스타일은 독특합니다. 강렬한 과일향, 깊은 풍미, 우아한 숙성은 포도밭에서 와인 저장고까지 천천히 점진적으로 진행되는 과정의 결과입니다.`],next:9}, 
    {id:9,type:"img",content:['moet_visuel.jpg'],next:10},  
    {id:10,type:"text",content:[`모엣&샹동의 다른 제품을 보고 싶으십니까 ?`],next:11},  
    {id:11,type:"menu",content:["예, 그러고 싶습니다.","아니요, 괜찮습니다."],choices:[12,13]},
    {id:12,type:"golink",content:["https://www.moet.com/kr-kr/our-champagnes-moet-chandon"],next:13}, 
    {id:13,type:"text",content:[`다른 것을 원하십니까 ?`],next:1},  
    {id:14,type:"img",content:['moet_visuel3.jpg'],next:15},  
    {id:15,type:"text",content:[`화사한 향기: 풋사과, 감귤류 등의 강렬한 과일 향, 그리고 곧바로 이어지는 미네랄과 하얀 꽃의 신선함이 먼저 다가옵니다. 그런 다음 브리오슈, 시리얼, 신선한 견과류의 황금색을 연상시키는 우아한 숙성이 느껴집니다.`,`풍부함과 섬세함이 결합된 풍미: 풍부하고 맛있는 배, 백도, 사과와 같은 흰살 과일의 상큼한 노트가 미세하고 가볍게 펼쳐집니다. 그런 다음 감귤류의 부드러운 생동감이 조금씩 섬세하게 이어집니다.`],next:13},  
    {id:16,type:"img",content:['moet_visuel2.jpg'],next:17},  
    {id:17,type:"text",content:[`모엣 & 샹동 샴페인은 익히지 않은 음식(스시 카르파치오), 신선한 과일, 굴 그리고 강황이나 카레와 같은 따뜻한 향신료를 사용한 음식에 잘 어울립니다.`],next:13},  
    {id:18,type:"img",content:['moet_rose_visuel1.jpg'],next:19},  
    {id:19,type:"text",content:[`A delicate nose: the lively, intense bouquet bursts with red fruits: wild strawberry, raspberry, cherry… A few rose petals then awaken floral facets, underscored by a slight hint of pepper.`,`An expressive, intense and supple palate: Strawberry, raspberry, redcurrant: the juicy, persistent intensity of red fruits seizes the palate, joined by first the fleshiness, then the firmness of peach. It draws to a close on the freshness of a subtle note of pepper and menthol.`],next:13}, 
    {id:20,type:"img",content:['moet_rose_visuel2.jpg'],next:21},  
    {id:21,type:"text",content:[`Pair your Moët & Chandon champagne with raw or simply grilled red meats, colourful summer vegetables, rockfish, shellfish bisque, fresh green herbs, or tart fresh red fruits.`],next:13},  
    {id:22,type:"special",content:['open_brut'],next:4},  
    {id:23,type:"special",content:['open_rose'],next:4},     
    {id:24,type:"text",content:['맘에 드십니까 ?'],next:25}, 
    {id:25,type:"menu",content:['예, 완벽합니다',`아니요, 꼭 그런 것은 아닙니다. 다른 메시지를 선택하고 싶습니다`],choices:[26,27]},
    {id:26,type:"text",content:[`매우 만족합니다.`,`이제 무엇을 도와드릴까요 ?`],next:28}, 
    {id:27,type:"text",content:['어떤 다른 메시지를 새기고 싶습니까 ?'],next:5},  
    {id:28,type:"menu",content:[

      `여전히 메시지를 바꿀 수 있습니까 ?`,
      `기프트 박스 맞춤 제작에 대한 자세한 정보는 어디에서 열람할 수 있습니까 ?`
    ],choices:[27,32]},
    {id:29,type:"text",content:[`여기에서 기프트 박스를 구입하세요 :`],next:34}, 
    {id:30,type:"text",content:[`배송 조건은 전자상거래 제휴업체에 따라 다릅니다. 제휴업체에 직접 연락하시기 바랍니다.`],next:33}, 
    {id:31,type:"text",content:[`고객서비스에 어떻게 문의할 수 있습니까 ?`],next:35}, 
    {id:32,type:"text",content:[`기프트 박스 맞춤 제작에 대한 자세한 정보는 어디에서 열람할 수 있습니까 ?`],next:36}, 
    {id:33,type:"text",content:[`다른 것을 원하십니까 ?`],next:28}, 
    {id:34,type:"link",content:[`https://www.millesima.fr/champagne-moet-chandon-brut-imperial-0000.html`],next:33},  
    {id:35,type:"link",content:[`https://www.moet.com/kr-kr/contact-us`],next:33},  
    {id:36,type:"link",content:[`https://www.moet.com/kr-kr/specially-yours`],next:33},  
    {id:37,type:"special",content:['mantra',10],next:24},   
    {id:38,type:"special",content:['mantra',11],next:24},  
    {id:39,type:"special",content:['mantra',12],next:24},  
    {id:40,type:"special",content:['mantra',13],next:24}, 
    {id:41,type:"special",content:['mantra',14],next:24},   
    {id:42,type:"special",content:['mantra',15],next:24},  
    {id:43,type:"special",content:['mantra',0],next:24},  
    {id:44,type:"special",content:['mantra',1],next:24}, 
    {id:45,type:"special",content:['mantra',2],next:24},   
    {id:46,type:"special",content:['mantra',3],next:24},  
    {id:47,type:"special",content:['mantra',4],next:24},  
    {id:48,type:"special",content:['mantra',5],next:24}, 
    {id:49,type:"special",content:['mantra',16],next:24},  
    {id:50,type:"special",content:['mantra',17],next:24},  
    {id:51,type:"special",content:['mantra',18],next:24}, 

];
