
export const languageFR = {
    open_box:'Ouvrir le coffret',
    intro_text: `Bonjour et bienvenue à la Conciergerie Moët & Chandon !<br/>Je suis à votre disposition pour vous guider tout au long de votre expérience de personnalisation.`,
    start_exp: "COMMENCER L'EXPERIENCE",
    customise:"Personnaliser l'Impérial Brut",
    customise_brut:"Personnaliser l'Impérial Brut",
    customise_rose:"Personnaliser le Rosé Impérial",
    bot_start:`N'hésitez pas à faire appel à moi en activant la cloche, j'ai le plaisir de vous guider.`,
    back:'Retour',
    customise_title:'Personnalisation de votre coffret',
    customise_content:`Veuillez renseigner un prénom, une date, une année ou la ville de votre choix dans le champ ci-dessous. (jusqu’à 14 lettres maximum)
    Tout produit personnalisé ne pourra être échangé ni remboursé.`,
    mantra_content:  `Veuillez renseigner un prénom, une date, une année ou la ville de votre choix dans le champ ci-dessous. (jusqu’à 14 lettres maximum)
    Tout produit personnalisé ne pourra être échangé ni remboursé.`,
    character_left:'14 caractères restants',
    customise_placeholder:'Entrez votre personnalisation ici',
    customise_error:"Nous sommes navré, ce texte n’est pas disponible à la personnalisation.",
    confirm:'VALIDER',
    ring:"Sonner",
    buy:`ACHETER`,
    box_title:`COFFRET PERSONNALISÉ`,
    composition:`Contient des sulfites. Degré d'alcool : 12°`,
    brut_name:`Champagne Moët & Chandon Impérial Brut`,
    brut_description:`Cuvée emblématique de la Maison depuis 1869, Moët Impérial en est l'expression accomplie et universelle.`,
    rose_name:`Champagne Moët & Chandon Rosé Impérial`,
    rose_description:`Le Rosé Impérial est l'expression la plus spontanée du style Moët & Chandon.`,
    again:`Recommencer`,
    landing_page:`Découvrir “Specially Yours”`
}

export const messagesFR=[
    {id:0,type:"text",content:["Bonjour, bienvenue à la Conciergerie Moët & Chandon.", "Comment puis-je vous aider ?"],next:1},
    {id:1,type:"menu",content:[
         "Je souhaite personnaliser un coffret.",
         "J'aimerais en savoir plus sur ce coffret.",
         "Parlez-moi du style Moët & Chandon.",
         "Quelles sont les notes de dégustation du Moët Brut Impérial ?",
         "Avec quels mets puis-je accompagner Moët Brut Impérial ?",
         "What food can I pair with Moët Rosé Impérial ?"],
         choices:[2,6,8,14,16,18,20]},
    {id:2,type:"text",content:["With pleasure ! Do you want to choose: "],next:3},  
    {id:3,type:"menu",content:["Moët Brut Impérial","Moët Rosé Impérial"],choices:[22,23]},
    {id:4,type:"text",content:[" Please choose your message below ↓"],next:5},  
    {id:5,type:"menu",content:[
         "CHEERS TO YOU",
         "HAPPY BIRTHDAY TO YOU",
         "CONGRATULATIONS TO YOU",
         "TO YOUR FUTURE",
         "YOU ARE GOING PLACES",
         "TO YOUR NEXT MILESTONE",
        ],
         choices:[43,44,45,46,47,48]},
    {id:6,type:"img",content:['img_perso.jpeg'],next:7},  
    {id:7,type:"text",content:[`Moët & Chandon is offering a customisation service, where you can have the first name, date, year or city of your choice inscribed on a limited-edition gift box. It is a great offer for champagne lovers wanting to surprise someone with Moët & Chandon Brut Impérial or Moët & Chandon Rosé Impérial, the House’s iconic cuvées!<br/><br/>When confirming your order, make sure that the customisation you have entered is correct. Customised products cannot be exchanged or refunded.`,`Anything else ?`],next:1}, 
    {id:8,type:"text",content:[`The Moët & Chandon style is unique: a bright fruitiness, a seductive palette and an elegant maturity. It is the result of a slow and gradual progression from vine to cellar.`],next:9}, 
    {id:9,type:"img",content:['moet_visuel.jpg'],next:10},  
    {id:10,type:"text",content:[`Would you like to see other products of the Moët & Chandon range ?`],next:11},  
    {id:11,type:"menu",content:["Yes, please.","No, thank you."],choices:[12,13]},
    {id:12,type:"golink",content:["https://www.moet.com/en-gb/our-champagnes-moet-chandon"],next:13}, 
    {id:13,type:"text",content:[`Anything else ?`],next:1},  
    {id:14,type:"img",content:['moet_visuel3.jpg'],next:15},  
    {id:15,type:"text",content:[`A sparkling nose: Green apple, citrus fruit… The captivating intensity of these fruity notes is immediately followed by a wave of mineral freshness and white flowers. The blonde notes of brioche, cereals and fresh walnuts then announce an elegant maturity.`,`A palate blending sumptuousness and subtlety: The sumptuous, delicious notes of white-fleshed fruits like pear, white peach and apple burst on the palate with light and fine bubbles. The soft vivacity of nuances of citrus fruit then gradually emerge, delicately lingering in the mouth.`],next:13},  
    {id:16,type:"img",content:['moet_visuel2.jpg'],next:17},  
    {id:17,type:"text",content:[`Accompany your Moët & Chandon Champagne with raw foods (sushi, carpaccio), fresh fruit, oysters or dishes cooked with spices such as turmeric or curry.`],next:13},  
    {id:18,type:"img",content:['moet_rose_visuel1.jpg'],next:19},  
    {id:19,type:"text",content:[`A delicate nose: the lively, intense bouquet bursts with red fruits: wild strawberry, raspberry, cherry… A few rose petals then awaken floral facets, underscored by a slight hint of pepper.`,`An expressive, intense and supple palate: Strawberry, raspberry, redcurrant: the juicy, persistent intensity of red fruits seizes the palate, joined by first the fleshiness, then the firmness of peach. It draws to a close on the freshness of a subtle note of pepper and menthol.`],next:13}, 
    {id:20,type:"img",content:['moet_rose_visuel2.jpg'],next:21},  
    {id:21,type:"text",content:[`Pair your Moët & Chandon champagne with raw or simply grilled red meats, colourful summer vegetables, rockfish, shellfish bisque, fresh green herbs, or tart fresh red fruits.`],next:13},  
    {id:22,type:"special",content:['open_brut'],next:4},  
    {id:23,type:"special",content:['open_rose'],next:4},     
    {id:24,type:"text",content:['Does this suit you ?'],next:25}, 
    {id:25,type:"menu",content:['Yes, it’s perfect.',`No, not exactly. I would like to choose another message.`],choices:[26,27]},
    {id:26,type:"text",content:[`I'm delighted to hear it.`,`How may I help next ?`],next:28}, 
    {id:27,type:"text",content:['What other message would you like to inscribe ?'],next:5},  
    {id:28,type:"menu",content:[
      'How do I purchase my gift box ?',
      `What are the terms and conditions of delivery ?`,
      `Can I still change my message ?`,
      `How to contact customer service ?`,
      `How can I find out more about gift box customisation ?`
    ],choices:[29,30,27,31,32]},
    {id:29,type:"text",content:[`Purchase your gift box here :`],next:34}, 
    {id:30,type:"text",content:[`The terms and conditions of delivery are determined by our e-commerce partner. Please contact them directly.`],next:33}, 
    {id:31,type:"text",content:[`Here is our contact form :`],next:35}, 
    {id:32,type:"text",content:[`Discover our Specially Yours offer:`],next:36}, 
    {id:33,type:"text",content:[`Anything else ?`],next:28}, 
    {id:34,type:"link",content:[`https://www.millesima.fr/champagne-moet-chandon-brut-imperial-0000.html`],next:33},  
    {id:35,type:"link",content:[`https://www.moet.com/en-int/contact-us`],next:33},  
    {id:36,type:"link",content:[`https://www.moet.com/en-int/specially-yours`],next:33},  
    {id:37,type:"special",content:['mantra',10],next:24},   
    {id:38,type:"special",content:['mantra',11],next:24},  
    {id:39,type:"special",content:['mantra',12],next:24},  
    {id:40,type:"special",content:['mantra',13],next:24}, 
    {id:41,type:"special",content:['mantra',14],next:24},   
    {id:42,type:"special",content:['mantra',15],next:24},  
    {id:43,type:"special",content:['mantra',0],next:24},  
    {id:44,type:"special",content:['mantra',1],next:24}, 
    {id:45,type:"special",content:['mantra',2],next:24},   
    {id:46,type:"special",content:['mantra',3],next:24},  
    {id:47,type:"special",content:['mantra',4],next:24},  
    {id:48,type:"special",content:['mantra',5],next:24}, 
];
