const market=location.hash ? location.hash : "#int" //INT, USA, CN
console.log('market',market)

var langDate;
var mantra_list;
var firstMantra;
var language;
var messages;
var onlyRose=false;
var onlyBrut=false;



//mantra

const mantraText=[
  "CHEERS TO YOU",
  "HAPPY BIRTHDAY TO YOU",
  "CONGRATULATIONS TO YOU",
  "TO YOUR FUTURE",
  "YOU ARE GOING PLACES",
  "TO YOUR NEXT MILESTONE",
  "无可替代 ",
  "有你真好",
  "藏不住的喜欢",
  "",
  "SAY YES TO LOVE",
  "SPECIALLY YOURS",
  "YOU ARE MY FAVORITE PERSON",
  "FROM MY HEART TO YOURS",
  "THE MOMENT WE MET I KNEW",
  "TO LIVE & LOVE WITH YOU",
  "마음을 담아",
  "새로운 도약을 위해",
  "특별한 당신에게"
]


///

  import {languageEN,messagesEN} from './js/languages/int.js'
  import {languageUS,messagesUS} from './js/languages/us.js'
  import {languageCN,messagesCN} from './js/languages/cn.js'
  import {languageKO,messagesKO} from './js/languages/ko.js'
  import {languageFR,messagesFR} from './js/languages/fr.js'

  switch(market){
    case '#int':
       langDate='en-EN';
       mantra_list=[0,1,2,3,4,5,10,11,12,13,14,15];
       firstMantra=0; 
       language=languageEN;
       messages=messagesEN;
       document.querySelector('.mhd-btn-cart').classList.add('hide');
      break;
      case '#usa':
        langDate='en-US';
        mantra_list=[0,1,2,3,4,5];
        firstMantra=0; 
        language=languageUS;
        messages=messagesUS;
       break;
       case '#cn':
        langDate='zh-cn';
        mantra_list=[10,12,11,6,7,8];
        firstMantra=10; 
        language=languageCN;
        messages=messagesCN;
        onlyRose=true;
        document.querySelector('.mhd-cta-push-brut').classList.add('none')
        document.querySelector('.mhd-btn-cart').classList.add('hide');
       break;
       case '#ko':
        langDate='ko-KR';
        mantra_list=[0,1,11,16,17,18];
        firstMantra=0; 
        language=languageKO;
        messages=messagesKO;
        onlyBrut=true;
        document.querySelector('.mhd-cta-push-brut').classList.add('none')
        document.querySelector('.mhd-btn-cart').classList.add('hide');
       break;

       case '#fr':
        langDate='fr-FR';
        mantra_list=[0,1,2,3,4,5,10,11,12,13,14,15];
        firstMantra=0; 
        language=languageFR;
        messages=messagesFR;
        onlyBrut=false;
        // document.querySelector('.mhd-cta-push-brut').classList.add('none')
        document.querySelector('.mhd-btn-cart').classList.add('hide');
       break;
  }
  
//init

import './styles.css'
import * as THREE from 'three'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { nameList } from './js/checkName.js'


const fontLoader = new FontLoader();

// translate
const translation=document.querySelectorAll('*[translate]')
const placeholders=document.querySelectorAll('*[ph_translate]')

translation.forEach(translate=>{
    //console.log(translate.getAttribute('translate'))
    translate.innerHTML=language[translate.getAttribute('translate')];
})

placeholders.forEach(translate=>{
    translate.setAttribute('placeholder',language[translate.getAttribute('ph_translate')]);
})




 /**
  * Debug
  */
 /*

  let stats = new Stats();
  stats.showPanel(0);
  document.body.appendChild(stats.dom);
 */

 //const gui = new dat.GUI()

 /**
  * Tools
  */

 const lerp = (x, y, a) => x * (1 - a) + y * a;
 const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
 const invlerp = (x, y, a) => clamp((a - x) / (y - x));
 const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));


var controls,interact,doors,planeWhite,face3b,face3c,face4b,face4c,groomInside,posGroom,office,office2,office2b,office3,officeGold1,officeGold2,office4,office5,office6,office7,comptoir,comptoir2,colonneLeft,colonneLeftDeco,colonneLeftTop,colonneRight,colonneRightDeco,colonneRightTop,moulure,moulure2,liftLeft,liftRight,liftTop,liftBottom,liftNumber,liftDoorL,liftDoorR,lTime,stepLift,liftInside,liftInsideOut,liftBtn,btnTop,btnBottom,trueBtnTop,trueBtnBottom,sign,signWhite,signText,shelves,shelves2,shelf0,shelf1,shelf2,shelf3,shelf4,shelf5,shelfv0,shelfv1, shelfv2,shelfv3,shelfv4,shelfv5,numbers,extraGift,extraGift1b,extraGift2,extraGift2b,extraGift3,extraGift3b,extraGift4,extraGift4b,extraGift5,extraGift5b,tabouret,seat,seat2,seatRing,bottomRing,middleRing,barreau1,barreau2,barreau3,barreau4,sonette,cloche,socle,tige,light,abaj,topLight,topLightInside,lightRing,tigeLight,socleLight,socleLight2,socleLight3,socleLight4,phoneBoxBack,phoneBoxTop,mini,tamp,miniline,mini2,mini3,mini4,mini5,mini6,mini7,mini8,minilinetop,miniTop2,miniTop3,miniTop4,miniTop5,miniTop6,miniTop7,miniTop8,miniLight1,miniLight2,mainLight,mainLight2,chariot,gift,compo,bottle,bottlePink;
var p,r,l,h,angleX,translateX,translateY,iks,igrek,camCustomX,camCustomY,near,far,bTime,cTime, miniPink,target,memoX,memoAngle,newAngle,memoAngleC,newAngleC;
var colorBox="brut";

 function isIpadOS() {
  return navigator.maxTouchPoints &&
   navigator.maxTouchPoints > 2 &&
   /MacIntel/.test(navigator.platform);
 }

 let isMobile = window.matchMedia("(max-width: 959px)").matches
 let desktop = !isMobile
 let touchDevice = (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);


 //console.log("isMobile", isMobile)

 /**
  * var
  */
 var interObj = [];
 var objectsToTest = [];
 var oTime = 0;
 var cTime = 0;
 var tick = 0;
 var step = "preloader"
 var angleSide = 17 * Math.PI / 32;
 var speed = 0;
 var angleY = 0;
 var angleYc = .9;
 var btnType;

 var scene, renderer, camera, coffret, plane, text, groomBox, pack;
 var face1, face2, face3, face4, face5, face6;

 var main = new THREE.Group();
 var elevator = new THREE.Group();
 var phoneBox = new THREE.Group();
 var etageres = new THREE.Group();
 var trolley = new THREE.Group();
 var customBox = new THREE.Group();
 var customBoxPink = new THREE.Group();


 const canvas = document.querySelector('#main-canvas')
 const cursor = document.querySelector('.mhd-cta-cursor')
 const exp = document.querySelector('.mhd-exp')

 const mouse = new THREE.Vector2()
 const raycaster = new THREE.Raycaster()


 var startEvent = desktop ? "mousedown" : "touchstart"
 var endEvent = desktop ? "mouseup" : "touchend"
 var moveEvent = desktop ? "mousemove" : "touchmove"

 var textReady = false;
 var textReadyPink = false;

 var phone;
 var nHover = -1;


 /**
  * Sizes
  */

 var beginX = isMobile ? 7.4 : 3.7;
 var beginY = .3;
 var endX = 2.9;
 var endY = .2;

 var customX = 1.5;

 const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
 }

 const box = {
  height: 2,
  width: .64
 };


 var preloader = document.querySelector('.circle1')

 const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {


   setTimeout(() => {

    step = "intro"

    if (!isMobile) {
     cursor.classList.add('active')
    }
    else {
     cursor.innerHTML = language.customise
    }

    document.querySelector('.mhd-preloader').classList.remove('visible');
    document.querySelector('.mhd-bkg').classList.remove('intro');
    document.querySelector('.mhd-bkg').classList.add('hover');
    document.querySelector('.mhd-start').classList.remove('hide');

    document.querySelector('.mhd-bkg').addEventListener('click', open)

    resizing();
    camera.position.set(beginX, beginY, 0);
    initMouse();
    animate();

   }, 1000)


  },

  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
   const progressRatio = itemsLoaded / itemsTotal
   //console.log(itemUrl, itemsLoaded, itemsTotal)

   length = 282 - progressRatio * 282;
   preloader.style.strokeDashoffset = length + "px";

  }
 )

 /**
  * Sounds
  */

 const bellSound = new Audio('sounds/bell.mp3')
 const elevatorSound = new Audio('sounds/elevator.mp3')
 const phoneSound = new Audio('sounds/phone_1.mp3')
 const explainSound = new Audio('sounds/explain.mp3')

 //textures
 const textureLoader = new THREE.TextureLoader(loadingManager);

 const frontTexture = textureLoader.load('textures/front2.jpg')
 const backTexture = textureLoader.load('textures/back.jpg')
 const sideTexture1 = textureLoader.load('textures/side1.jpg')
 const sideTexture2 = textureLoader.load('textures/side2.jpg')
 const topTexture = textureLoader.load('textures/top.jpg')
 const bottomTexture = textureLoader.load('textures/bottom3.jpg')


 const frontTextureRose = textureLoader.load('textures/frontRose.jpg')
 const backTextureRose = textureLoader.load('textures/backRose.jpg')
 const sideTexture1Rose = textureLoader.load('textures/sideRose1.jpg')
 const sideTexture2Rose = textureLoader.load('textures/sideRose2.jpg')
 const topTextureRose = textureLoader.load('textures/topRose.jpg')
 const bottomTextureRose = textureLoader.load('textures/topRose.jpg')

 const etiquetteTexture = textureLoader.load('textures/etiquette.jpg')
 const etiquettePinkTexture = textureLoader.load('textures/etiquetteRose.jpg')
 //const tamponTexture = textureLoader.load('textures/tampon.png')

var selectBottom=  document.querySelector('.select-bottom');
// selectBottom.innerHTML='';

const mantraTextures=[];
const mantraTexturesPink=[];

mantra_list.forEach(mantra=>{
  mantraTextures[mantra]=textureLoader.load('textures/front2.jpg')
  mantraTexturesPink[mantra] = textureLoader.load('textures/frontPinkPlain.jpg')

  // selectBottom.innerHTML+=`<div class="mantra-item" data-num="${mantra}">${mantraText[mantra]}</div> `
})



 const logoTexture = textureLoader.load('textures/logo3.png')
 const conciergeTexture = textureLoader.load('textures/gift_shop.png')
 const liftTexture = textureLoader.load('textures/lift3.jpg')

 const front = new THREE.MeshBasicMaterial({ map: frontTexture });
 const side1 = new THREE.MeshBasicMaterial({ map: sideTexture1 });
 const side2 = new THREE.MeshBasicMaterial({ map: sideTexture2 });
 const topSide = new THREE.MeshBasicMaterial({ map: topTexture });
 const bottomSide = new THREE.MeshBasicMaterial({ map: bottomTexture });
 const backSide = new THREE.MeshBasicMaterial({ map: backTexture });

 const frontPink = new THREE.MeshBasicMaterial({ map: frontTextureRose });
 const side1Pink = new THREE.MeshBasicMaterial({ map: sideTexture1Rose });
 const side2Pink = new THREE.MeshBasicMaterial({ map: sideTexture2Rose });

 /*
 const topSidePink = new THREE.MeshBasicMaterial({ map: topTextureRose });
 const bottomSidePink = new THREE.MeshBasicMaterial({ map: bottomTextureRose });
 const backSidePink = new THREE.MeshBasicMaterial({ map: backTextureRose });
 */

 //const tampon = new THREE.MeshBasicMaterial({ map: tamponTexture, alphaTest: .5 });

 const etiquette = new THREE.MeshBasicMaterial({ map: etiquetteTexture });
 const etiquettePink = new THREE.MeshBasicMaterial({ map: etiquettePinkTexture });
 const logo = new THREE.MeshBasicMaterial({ map: logoTexture, transparent: true, alphaTest: .05 });
 //const colonne1 = new THREE.MeshBasicMaterial({ map: colonneTexture1, transparent: true, alphaTest: .05 });
 //const colonne2 = new THREE.MeshBasicMaterial({ map: colonneTexture2, transparent: true, alphaTest: .05 });
 const conciergerie = new THREE.MeshBasicMaterial({ map: conciergeTexture, transparent: true, alphaTest: .05 });
 const lift = new THREE.MeshBasicMaterial({ map: liftTexture, side: THREE.BackSide, });

 const blackTexture = textureLoader.load('textures/black.jpg')
 const goldTexture = textureLoader.load('textures/gold7.jpg')
 const pinkTexture = textureLoader.load('textures/pink.jpg')
 const goldTextureInv = textureLoader.load('textures/gold7inv.jpg')
 const whiteTexture = textureLoader.load('textures/white2.jpg')
 const brownTexture = textureLoader.load('textures/black2.jpeg')
 const redTexture = textureLoader.load('textures/red.jpg')
 const glassTexture = textureLoader.load('textures/glass2.jpg')

 const black = new THREE.MeshMatcapMaterial({ matcap: blackTexture });
 const gold = new THREE.MeshMatcapMaterial({ matcap: goldTexture });
 const pink = new THREE.MeshMatcapMaterial({ matcap: pinkTexture });
 const white = new THREE.MeshMatcapMaterial({ matcap: whiteTexture });
 const brown = new THREE.MeshMatcapMaterial({ matcap: brownTexture });
 const red = new THREE.MeshMatcapMaterial({ matcap: redTexture });
 const goldInside = new THREE.MeshMatcapMaterial({ matcap: goldTexture, side: THREE.BackSide });
 const goldInverse = new THREE.MeshMatcapMaterial({ matcap: goldTextureInv });
 const transparent = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });
 const glass = new THREE.MeshMatcapMaterial({ matcap: glassTexture, transparent: true, opacity: .95 });
 const hide = new THREE.MeshBasicMaterial({ colorWrite: false });

 const loader = new THREE.CubeTextureLoader();

 const numberTx = []

 for (var i = 1; i < 26; i++) {

  numberTx.push([
   new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/numbers/' + i + '.png'), transparent: true, alphaTest: .2 }),
   transparent
  ]);

 }

 //front
 var mt_f1 = [
  side1,
  gold, //gold
  gold,
  gold,
  gold,
  gold
 ];

 //sol
 var mt_f2 = [
  gold,
  gold,
  gold, //droite
  white, //gauche
  brown, //sol
  gold, //dessous
 ];

 var mt_f2_after = [
  brown,
  brown,
  brown, //droite
  brown, //gauche
  brown, //sol
  white, //dessous
 ];

 //arriere
 var mt_f3 = [
  white,
  side1,
  white,
  white,
  transparent,
  transparent,
 ];

 var mt_f3_after = [
  transparent,
  side2,
  white,
  white,
  transparent,
  transparent,
 ];

 //haut
 var mt_f4 = [
  gold,
  transparent,
  white,
  white,
  front,
  white,
 ];

 var mt_f4_after = [
  white,
  transparent,
  white,
  white,
  front,
  transparent,
 ];

 var mt_f5 = [
  gold,
  gold,
  bottomSide, //droite
  gold, //gold
  gold,
  gold,
 ];

 var mt_f6 = [
  gold,
  gold,
  gold, //droite - gold
  topSide, //gauche
  gold,
  gold,
 ];


 var sign_mat = [
  gold,
  gold,
  gold, //droite
  gold, //gauche
  gold, //sol
  white, //dessous
 ];


 var frontOffice = [
  logo,
  white,
  white,
  white,
  white,
  white,
 ];

/*
 var colonne = [
  colonne1,
  colonne2,
  transparent,
  transparent,
  colonne2,
  colonne2,
 ];
*/

 var colonneTop = [
  gold,
  gold,
  white,
  white,
  white,
  white,

 ];

 var coffretMat = [
  side1,
  side2,
  bottomSide,
  topSide,
  front,
  backSide
 ];

 var coffretMatPink = [
  side1Pink,
  side2Pink,
  bottomSide,
  topSide,
  frontPink,
  backSide
 ];



 var insideLift = [
  lift,
  lift,
  new THREE.MeshMatcapMaterial({ matcap: brownTexture, side: THREE.BackSide }),
  new THREE.MeshMatcapMaterial({ matcap: brownTexture, side: THREE.BackSide }),
  lift,
  lift,
 ];



 const resizing = () => {
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  isMobile = window.matchMedia("(max-width: 959px)").matches
  desktop = !isMobile

  //controls.enabled = isMobile;
  beginX = isMobile ? 7.4 : 3.7;
  //console.log("isMobile", isMobile)

  startEvent = desktop ? "mousedown" : "touchstart"
  endEvent = desktop ? "mouseup" : "touchend"
  moveEvent = desktop ? "mousemove" : "touchmove"

 }


 function init() {
  renderer = new THREE.WebGLRenderer({
   canvas: canvas,
   antialias: true,
   performance: "default"
  })

  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(canvas.clientWidth, canvas.clientHeight);

  scene = new THREE.Scene();

  scene.fog = new THREE.Fog(0xffffff, 8, 12);
  scene.background = new THREE.Color(0xffffff);


  /**
   * controls
   */

  camera = new THREE.PerspectiveCamera(
   35, //FOV
   window.innerWidth / window.innerHeight, //aspect
   0.1, //near clipping plane
   1000 //far clipping plane
  );


  camera.position.set(beginX, beginY, 0);
  /*
    window.addEventListener('resize', function() {
     camera.aspect = canvas.clientWidth / canvas.clientHeight;
     camera.updateProjectionMatrix();
     renderer.setSize(canvas.clientWidth, canvas.clientHeight);
     console.log(canvas.clientWidth)
    }, false);
    */



  window.addEventListener('resize', () => {
   resizing();
  })


  /*
controls = new OrbitControls(camera, renderer.domElement);

  controls.rotateSpeed = 1;
  controls.enableDamping = true;
  controls.dampingFactor = .05;
  controls.enableZoom = true;
  controls.enabled = false;
  controls.enablePan = false;




  if (isMobile == "debug") {
   controls.enabled = true;
   controls.minDistance = 4;
   controls.maxDistance = 8;

   controls.minPolarAngle = Math.PI / 2.2;
   controls.maxPolarAngle = Math.PI / 2;

   controls.minAzimuthAngle = .9;
   controls.maxAzimuthAngle = Math.PI - .45;

  }
  /**
   * objects
   */


  scene.add(main);

  const bakedShadow = textureLoader.load('textures/bakedShadow.jpg')
  const groomIntroTx = textureLoader.load('textures/groomIntro2.png')
  const groomInsideTx = textureLoader.load('textures/groom_inside3.png')

  // plan
  plane = new THREE.Mesh(
   new THREE.PlaneGeometry(20, 20),
   new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.FrontSide, map: bakedShadow, opacity: .1, transparent: true })
  );


  plane.rotation.x = -Math.PI / 2;
  plane.rotation.z = Math.PI / 2;
  plane.position.y = -box.width / 2 - .064;
  //main.add(plane);
  plane.name = "plane"

  planeWhite = new THREE.Mesh(
   new THREE.PlaneGeometry(20, 20),
   new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.FrontSide })
  );

  planeWhite.rotation.x = -Math.PI / 2;
  planeWhite.rotation.z = Math.PI / 2;
  planeWhite.position.y = -box.width / 2 - .065;
  main.add(planeWhite);
  plane.name = "plane"
  planeWhite.position.y = -box.width / 2;

  // groom1
  groomBox = new THREE.Mesh(
   new THREE.PlaneGeometry(.3, .9),
   new THREE.MeshBasicMaterial({ alphaTest: .5, side: THREE.FrontSide, map: groomIntroTx, transparent: true })
  );

  groomBox.rotation.y = Math.PI / 2;
  groomBox.position.x = -.2;
  groomBox.position.y = -box.width / 2 + (.45) * 1.3;
  groomBox.scale.set(1.3, 1.3, 1.3)
  main.add(groomBox);


  var coffret = new THREE.Group();
  main.add(coffret);
  coffret.name = "coffret"


  /*
    coffret = new THREE.Mesh(
     new THREE.BoxGeometry(box.width, box.height, box.width),
     materials
    );
    */

  var deep = .01;
  var small = .001;

  //front
  face1 = new THREE.Mesh(
   new THREE.BoxGeometry(deep, box.height, box.width, 100, 100, 100),
   mt_f1
  );

  //bas
  face2 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width - deep, box.height, deep),
   mt_f2
  );

  //fond
  face3 = new THREE.Mesh(
   new THREE.BoxGeometry(deep, box.height, box.width),
   mt_f3
  );

  face3b = new THREE.Mesh(
   new THREE.BoxGeometry(deep, .62, box.width),
   white
  );

  face3c = new THREE.Mesh(
   new THREE.BoxGeometry(deep, .62, .4),
   white
  );


  //haut
  face4 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width - deep, box.height, deep),
   mt_f4
  );

  face4b = new THREE.Mesh(
   new THREE.BoxGeometry(.4, box.height - deep, deep),
   white
  );

  face4c = new THREE.Mesh(
   new THREE.BoxGeometry(.3, box.height - deep, deep),
   white
  );


  //droite
  face5 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width - deep, deep, box.width - deep * 2),
   mt_f5
  );

  //gauche
  face6 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width - deep, deep, box.width - deep * 2),
   mt_f6
  );


  face1.position.x = box.width / 2
  face1.position.z = -box.width / 2 + deep / 2
  face1.geometry.translate(0, 0, box.width / 2 - deep / 2);

  face2.position.z = -box.width / 2 + deep / 2

  face3.position.x = -box.width / 2
  face3b.position.x = -box.width / 2 + .005
  face3c.position.x = -box.width / 2 + .0055
  face3c.position.z = -box.width / 2 + .14

  face4.position.z = box.width / 2 - deep / 2
  face4.position.x = -box.width / 2
  face4.geometry.translate(box.width / 2, 0, 0);

  face4b.position.z = -.005
  face4b.position.x = .43

  face4c.position.z = -.005
  face4c.position.x = .15

  face5.position.y = box.height / 2 - deep / 2
  face5.position.z = -box.width / 2 + deep / 2
  face5.geometry.translate(0, 0, box.width / 2 - deep / 2);

  face6.position.y = -box.height / 2 + deep / 2
  face6.position.z = -box.width / 2 + deep / 2
  face6.geometry.translate(0, 0, box.width / 2 - deep / 2);

  coffret.add(face1)
  coffret.add(face2)
  coffret.add(face3)
  coffret.add(face4)
  coffret.add(face5)
  coffret.add(face6)

  coffret.add(face3b)
  coffret.add(face3c)

  face4.add(face4b)
  face4.add(face4c)

  coffret.rotation.x = -Math.PI / 2;


  //////////////////////////// Extra


  // groom1
  groomInside = new THREE.Mesh(
   new THREE.PlaneGeometry(.45, 1.01),
   new THREE.MeshBasicMaterial({ alphaTest: .5, side: THREE.FrontSide, map: groomInsideTx, transparent: true })
  );

  posGroom = -box.width / 2 + (.45) * .8;

  groomInside.rotation.y = Math.PI / 2;
  groomInside.position.x = -.1;
  groomInside.position.y = posGroom - .2;
  groomInside.scale.set(.8, .8, .8)
  main.add(groomInside);


  //office

  office = new THREE.Mesh(
   new THREE.BoxGeometry(.15, .37, .4),
   white
  );

  office2 = new THREE.Mesh(
   new THREE.BoxGeometry(.3, .37, .10),
   white
  );

  office2b = new THREE.Mesh(
   new THREE.BoxGeometry(.25, .32, .10),
   white
  );

  office3 = new THREE.Mesh(
   new THREE.BoxGeometry(.03, .33, .33),
   frontOffice
  );

  officeGold1 = new THREE.Mesh(
   new THREE.BoxGeometry(.125, .05, .675),
   gold
  );
  officeGold1.position.x = .15;
  officeGold1.position.z = 0;
  officeGold1.position.y = -box.width / 2;
  main.add(officeGold1)


  officeGold2 = new THREE.Mesh(
   new THREE.BoxGeometry(.31, .05, .075),
   gold
  );
  officeGold2.position.x = -.065;
  officeGold2.position.z = -.3;
  officeGold2.position.y = -box.width / 2;
  main.add(officeGold2)

  office.position.x = .15;
  office.position.z = .15;
  office.position.y = -box.width / 2 + .21;
  main.add(office)


  office2.position.x = -.075;
  office2.position.z = -.3;
  office2.position.y = -box.width / 2 + .21;
  main.add(office2)


  office2b.position.x = -.075;
  office2b.position.z = -.305;
  office2b.position.y = -box.width / 2 + .21;
  main.add(office2b)


  office3.position.x = .22;
  office3.position.z = .15;
  office3.position.y = -box.width / 2 + .21;
  main.add(office3)



  office4 = new THREE.Mesh(
   new THREE.BoxGeometry(.15, .01, .3),
   white
  );
  office4.position.x = .15;
  office4.position.z = -.2;
  office4.position.y = -box.width / 2 + .2;
  main.add(office4)


  office5 = new THREE.Mesh(
   new THREE.BoxGeometry(.15, .02, .3),
   white
  );
  office5.position.x = .15;
  office5.position.z = -.2;
  office5.position.y = .065;
  main.add(office5)

  office6 = new THREE.Mesh(
   new THREE.BoxGeometry(.15, .02, .3),
   white
  );
  office6.position.x = .15;
  office6.position.z = -.2;
  office6.position.y = -.285;
  main.add(office6)

  office7 = new THREE.Mesh(
   new THREE.BoxGeometry(.01, .37, .3),
   white
  );
  office7.position.x = .075;
  office7.position.z = -.2;
  office7.position.y = -box.width / 2 + .21;
  main.add(office7)

  comptoir = new THREE.Mesh(
   new THREE.BoxGeometry(.20, .015, .75),
   gold
  );

  comptoir2 = new THREE.Mesh(
   new THREE.BoxGeometry(.45, .015, .15),
   gold
  );


  comptoir.position.x = .15;
  comptoir.position.y = .08;
  main.add(comptoir)

  comptoir2.position.x = 0;
  comptoir2.position.y = .08;
  comptoir2.position.z = -.3;
  main.add(comptoir2)


  //colonnes

  colonneLeft = new THREE.Mesh(
   new THREE.BoxGeometry(.095, box.width - deep, .145),
   white
  );


  colonneLeftTop = new THREE.Mesh(
   new THREE.BoxGeometry(box.width, .145, .095),
   colonneTop
  );

  colonneLeft.position.x = -box.width / 2 + .05;
  colonneLeft.position.z = -box.height / 2 + .075
  main.add(colonneLeft)


  colonneLeftTop.position.x = box.width / 2 - deep;
  colonneLeftTop.position.y = -box.height / 2 + .075;
  colonneLeftTop.position.z = -.05;
  face4.add(colonneLeftTop)


  colonneRight = new THREE.Mesh(
   new THREE.BoxGeometry(.095, box.width - deep, .145),
   white
  );

  colonneRightTop = new THREE.Mesh(
   new THREE.BoxGeometry(box.width, .145, .095),
   colonneTop
  );

  colonneRight.position.x = -box.width / 2 + .05;
  colonneRight.position.z = +box.height / 2 - .075
  main.add(colonneRight)


  colonneRightTop.position.x = box.width / 2 - deep;
  colonneRightTop.position.y = box.height / 2 - .075;
  colonneRightTop.position.z = -.05;
  face4.add(colonneRightTop)


  //ascenceur


  main.add(elevator);
  elevator.position.y = -.3;

  liftLeft = new THREE.Mesh(
   new THREE.BoxGeometry(.03, .9, .1),
   gold
  );
  liftLeft.position.x = -box.width / 2 + .03
  liftLeft.position.z = box.height / 2 - .2
  liftLeft.position.y = -box.width / 2 + .45;
  elevator.add(liftLeft)

  liftRight = new THREE.Mesh(
   new THREE.BoxGeometry(.03, .9, .1),
   gold
  );
  liftRight.position.x = -box.width / 2 + .03
  liftRight.position.z = box.height / 2 - .7
  liftRight.position.y = -box.width / 2 + .45;
  elevator.add(liftRight)


  liftTop = new THREE.Mesh(
   new THREE.BoxGeometry(.03, .1, .6),
   gold
  );
  liftTop.position.x = -box.width / 2 + .03
  liftTop.position.z = box.height / 2 - .45
  liftTop.position.y = -box.width / 2 + .85;
  elevator.add(liftTop)

  liftBottom = new THREE.Mesh(
   new THREE.BoxGeometry(.03, .01, .6),
   gold
  );
  liftBottom.position.x = -box.width / 2 + .03
  liftBottom.position.z = box.height / 2 - .45
  liftBottom.position.y = -box.width / 2 + .008;
  main.add(liftBottom)

  liftNumber = new THREE.Mesh(
   new THREE.BoxGeometry(.03, .01, .1),
   brown
  );
  liftNumber.position.x = -box.width / 2 + .04
  liftNumber.position.z = box.height / 2 - .45
  liftNumber.position.y = -box.width / 2 + .85;
  elevator.add(liftNumber)


  liftDoorL = new THREE.Mesh(
   new THREE.BoxGeometry(.025, .8, .2),
   gold
  );
  liftDoorL.position.x = -box.width / 2 + .015
  liftDoorL.position.z = box.height / 2 - .35
  liftDoorL.position.y = -box.width / 2 + .4;
  elevator.add(liftDoorL)
  liftDoorL.rotation.y = -.05


  liftDoorR = new THREE.Mesh(
   new THREE.BoxGeometry(.025, .8, .2),
   gold
  );
  liftDoorR.position.x = -box.width / 2 + .015
  liftDoorR.position.z = box.height / 2 - .55
  liftDoorR.position.y = -box.width / 2 + .4;
  elevator.add(liftDoorR)
  liftDoorR.rotation.y = .05


  lTime = 0;

  p = .015
  r = .55
  l = .35

  stepLift = ""

  liftInside = new THREE.Mesh(
   new THREE.BoxGeometry(.4, .8, .5),
   insideLift
  );
  liftInside.position.x = -box.width / 2 - .195
  liftInside.position.z = box.height / 2 - .45
  liftInside.position.y = -box.width / 2 + .41;
  elevator.add(liftInside)


  liftInsideOut = new THREE.Mesh(
   new THREE.BoxBufferGeometry(.45, .85, .55),
   hide
  );
  liftInsideOut.position.x = -box.width / 2 - .195
  liftInsideOut.position.z = box.height / 2 - .45
  liftInsideOut.position.y = -box.width / 2 + .41;
  //elevator.add(liftInsideOut)


  //liftInsideOut.geometry.faces.splice(0, 2);

// console.log(liftInsideOut.geometry)

  liftInsideOut.renderOrder = 1
  liftInside.renderOrder = 2

  liftBtn = new THREE.Mesh(
   new THREE.BoxGeometry(.03, .13, .07),
   gold
  );
  liftBtn.position.x = -box.width / 2 + .09
  liftBtn.position.z = box.height / 2 - .075
  liftBtn.position.y = -box.width / 2 + .5;
  main.add(liftBtn)

  liftBtn.name = "elevator"
  objectsToTest.push(liftBtn)

  btnTop = new THREE.Mesh(
   new THREE.CylinderGeometry(.017, .017, .035, 16),
   white
  );

  btnTop.rotation.x = Math.PI / 2
  btnTop.rotation.z = Math.PI / 2

  btnTop.position.x = -box.width / 2 + .09
  btnTop.position.z = box.height / 2 - .075
  btnTop.position.y = -box.width / 2 + .525;

  main.add(btnTop)

  btnBottom = new THREE.Mesh(
   new THREE.CylinderGeometry(.017, .017, .035, 16),
   white
  );

  btnBottom.rotation.x = Math.PI / 2
  btnBottom.rotation.z = Math.PI / 2

  btnBottom.position.x = -box.width / 2 + .09
  btnBottom.position.z = box.height / 2 - .075
  btnBottom.position.y = -box.width / 2 + .475;

  main.add(btnBottom)



  trueBtnTop = new THREE.Mesh(
   new THREE.CylinderGeometry(.014, .014, .035, 16),
   gold
  );

  trueBtnTop.rotation.x = Math.PI / 2
  trueBtnTop.rotation.z = Math.PI / 2

  trueBtnTop.position.x = -box.width / 2 + .092
  trueBtnTop.position.z = box.height / 2 - .075
  trueBtnTop.position.y = -box.width / 2 + .525;

  main.add(trueBtnTop)


  trueBtnBottom = new THREE.Mesh(
   new THREE.CylinderGeometry(.014, .014, .035, 16),
   gold
  );

  trueBtnBottom.rotation.x = Math.PI / 2
  trueBtnBottom.rotation.z = Math.PI / 2

  trueBtnBottom.position.x = -box.width / 2 + .092
  trueBtnBottom.position.z = box.height / 2 - .075
  trueBtnBottom.position.y = -box.width / 2 + .475;

  main.add(trueBtnBottom)

  //signe

var bigSign=new THREE.Group();
face4.add(bigSign);

  sign = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .45, .02),
   gold
  );
  sign.position.x = box.width / 2 + .12
  sign.position.z = -.01
  bigSign.add(sign)

  signWhite = new THREE.Mesh(
   new THREE.BoxGeometry(.09, .43, .02),
   white
  );
  signWhite.position.x = box.width / 2 + .12
  signWhite.position.z = -.02
  bigSign.add(signWhite)

  signText = new THREE.Mesh(
   new THREE.PlaneGeometry(.09, .38),
   conciergerie
  );
  signText.position.x = box.width / 2 + .12
  signText.position.z = -.035
  signText.rotation.x = Math.PI
  bigSign.add(signText)

  bigSign.scale.set(.75,.75,.75)
  bigSign.position.x=.1
  bigSign.position.z=-.01

  // etagere
  shelves = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .44, .55),
   brown
  );
  shelves.position.x = -box.width / 2 + .05
  shelves.position.z = -box.height / 2 + .42
  shelves.position.y = -box.width / 2 + .22;
  etageres.add(shelves)
  shelves.name = "etagere"

  shelves2 = new THREE.Mesh(
   new THREE.BoxGeometry(.01, .44, .55),
   brown
  );
  shelves2.position.x = -box.width / 2 + .01
  shelves2.position.z = -box.height / 2 + .42
  shelves2.position.y = .34;
  etageres.add(shelves2)

  shelf0 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .01, .55),
   brown
  );
  shelf0.position.x = -box.width / 2 + .05
  shelf0.position.z = -box.height / 2 + .42
  shelf0.position.y = .205;
  etageres.add(shelf0)

  shelf1 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .01, .55),
   brown
  );
  shelf1.position.x = -box.width / 2 + .05
  shelf1.position.z = -box.height / 2 + .42
  shelf1.position.y = .295;
  etageres.add(shelf1)

  shelf2 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .01, .55),
   brown
  );
  shelf2.position.x = -box.width / 2 + .05
  shelf2.position.z = -box.height / 2 + .42
  shelf2.position.y = .385;
  etageres.add(shelf2)

  shelf3 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .01, .55),
   brown
  );
  shelf3.position.x = -box.width / 2 + .05
  shelf3.position.z = -box.height / 2 + .42
  shelf3.position.y = .475;
  etageres.add(shelf3)


  shelf5 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .024, .55),
   brown
  );
  shelf5.position.x = -box.width / 2 + .05
  shelf5.position.z = -box.height / 2 + .42
  shelf5.position.y = .56;
  etageres.add(shelf5)



  shelfv0 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .45, .03),
   brown
  );
  shelfv0.position.x = -box.width / 2 + .05
  shelfv0.position.z = -.32
  shelfv0.position.y = .345;
  etageres.add(shelfv0)


  shelfv1 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .45, .01),
   brown
  );
  shelfv1.position.x = -box.width / 2 + .05
  shelfv1.position.z = -.43
  shelfv1.position.y = .345;
  etageres.add(shelfv1)

  shelfv2 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .45, .01),
   brown
  );
  shelfv2.position.x = -box.width / 2 + .05
  shelfv2.position.z = -.53
  shelfv2.position.y = .345;
  etageres.add(shelfv2)

  shelfv3 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .45, .01),
   brown
  );
  shelfv3.position.x = -box.width / 2 + .05
  shelfv3.position.z = -.63
  shelfv3.position.y = .345;
  etageres.add(shelfv3)

  shelfv4 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .45, .01),
   brown
  );
  shelfv4.position.x = -box.width / 2 + .05
  shelfv4.position.z = -.73
  shelfv4.position.y = .345;
  etageres.add(shelfv4)


  shelfv5 = new THREE.Mesh(
   new THREE.BoxGeometry(.1, .45, .03),
   brown
  );
  shelfv5.position.x = -box.width / 2 + .05
  shelfv5.position.z = -.84
  shelfv5.position.y = .345;
  etageres.add(shelfv5)

  var font;

  doors = [];
  numbers = [];


  for (i = 0; i < 25; i++) {
   doors.push(new THREE.Mesh(
    new THREE.BoxGeometry(.005, .08, .09),
    gold
   ))

   doors[i].geometry.translate(0, 0, .045);
   doors[i].position.z = shelves.position.z - (i % 5) * .1 + .155
   doors[i].position.x = shelves.position.x + .05
   doors[i].position.y = box.width - .05 - Math.floor(i / 5) * .09 - .07


   doors[i].angleStart = doors[i].angle = doors[i].rotation.y = Math.random() / 10;


   //doors[i].rotation.y = 0
   doors[i].name = "door"
   doors[i].number = i


   etageres.add(doors[i])
   objectsToTest.push(doors[i])


   numbers.push(new THREE.Mesh(
    new THREE.BoxGeometry(.005, .08, .09),
    numberTx[i]
   ))


   numbers[i].geometry.translate(0, 0, .045);
   numbers[i].position.z = shelves.position.z - (i % 5) * .1 + .155
   numbers[i].position.x = shelves.position.x + .055
   numbers[i].position.y = box.width - .05 - Math.floor(i / 5) * .09 - .07

   numbers[i].rotation.y = doors[i].rotation.y;
   etageres.add(numbers[i])

  }

  main.add(etageres)
  etageres.position.y = -.3


  extraGift = new THREE.Mesh(
   new THREE.BoxGeometry(.05, .05, .05),
   gold
  );

  etageres.add(extraGift)
  extraGift.position.set(-.26, .415, -.37)
  extraGift.rotation.y = Math.PI / 12

  extraGift1b = new THREE.Mesh(
   new THREE.BoxGeometry(.05, .02, .055),
   gold
  );


  etageres.add(extraGift1b)
  extraGift1b.position.set(-.26, .435, -.37)
  extraGift1b.rotation.y = Math.PI / 12


  extraGift2 = new THREE.Mesh(
   new THREE.BoxGeometry(.07, .05, .07),
   gold
  );
  etageres.add(extraGift2)
  extraGift2.position.set(-.26, .145, -.78)

  extraGift2b = new THREE.Mesh(
   new THREE.BoxGeometry(.075, .055, .02),
   white
  );
  etageres.add(extraGift2b)
  extraGift2b.position.set(-.26, .145, -.78)


  extraGift3 = new THREE.Mesh(
   new THREE.BoxGeometry(.07, .05, .07),
   gold
  );
  etageres.add(extraGift3)
  extraGift3.position.set(-.28, .505, -.475)
  extraGift3.rotation.y = .5

  extraGift3b = new THREE.Mesh(
   new THREE.BoxGeometry(.07, .05, .07),
   white
  );
  etageres.add(extraGift3b)
  extraGift3b.position.set(-.28, .505, -.47)


  extraGift4 = new THREE.Mesh(
   new THREE.BoxGeometry(.05, .07, .05),
   gold
  );
  etageres.add(extraGift4)
  extraGift4.position.set(-.26, .155, -.577)

  extraGift4b = new THREE.Mesh(
   new THREE.BoxGeometry(.055, .075, .01),
   white
  );
  etageres.add(extraGift4b)
  extraGift4b.position.set(-.26, .155, -.577)



  extraGift5 = new THREE.Mesh(
   new THREE.BoxGeometry(.05, .07, .05),
   white
  );
  etageres.add(extraGift5)
  extraGift5.position.set(-.26, .335, -.78)

  extraGift5b = new THREE.Mesh(
   new THREE.BoxGeometry(.055, .075, .01),
   gold
  );
  etageres.add(extraGift5b)
  extraGift5b.position.set(-.26, .335, -.78)

  //chaise

  tabouret = new THREE.Group();

  seat = new THREE.Mesh(
   new THREE.CylinderGeometry(.06, .07, .035, 16),
   red
  );
  seat.position.y = .005

  seat2 = new THREE.Mesh(
   new THREE.TorusGeometry(.05, .025, 16, 100),
   red
  );

  seat2.rotation.x = Math.PI / 2;


  seatRing = new THREE.Mesh(
   new THREE.TorusGeometry(.06, .004, 10, 100),
   gold
  );
  seatRing.position.y = -.025;
  seatRing.rotation.x = Math.PI / 2;


  bottomRing = new THREE.Mesh(
   new THREE.TorusGeometry(.07, .005, 12, 100),
   gold
  );
  bottomRing.position.y = -.26;
  bottomRing.rotation.x = Math.PI / 2;


  middleRing = new THREE.Mesh(
   new THREE.TorusGeometry(.065, .004, 12, 100, Math.PI),
   gold
  );
  middleRing.position.y = -.16;
  middleRing.rotation.x = Math.PI / 2;


  barreau1 = new THREE.Mesh(
   new THREE.CylinderGeometry(.005, .005, .25, 8),
   gold
  );

  barreau1.rotation.x = Math.PI / 40
  barreau1.position.y = -.13
  barreau1.position.z = -.055

  barreau2 = new THREE.Mesh(
   new THREE.CylinderGeometry(.005, .005, .25, 8),
   gold
  );

  barreau2.rotation.x = -Math.PI / 40
  barreau2.position.y = -.13

  barreau2.position.z = .055


  barreau3 = new THREE.Mesh(
   new THREE.CylinderGeometry(.005, .005, .25, 8),
   gold
  );

  barreau3.rotation.z = Math.PI / 40
  barreau3.position.y = -.13
  barreau3.position.x = .055


  barreau4 = new THREE.Mesh(
   new THREE.CylinderGeometry(.005, .005, .25, 8),
   gold
  );

  barreau4.rotation.z = -Math.PI / 40
  barreau4.position.y = -.13
  barreau4.position.x = -.055

  tabouret.add(seat)
  tabouret.add(seat2)
  tabouret.add(seatRing)
  tabouret.add(bottomRing)
  tabouret.add(middleRing)
  tabouret.add(barreau1)
  tabouret.add(barreau2)
  tabouret.add(barreau3)
  tabouret.add(barreau4)

  tabouret.position.x = -.05;
  tabouret.position.z = -.55;
  tabouret.position.y = -.05;

  tabouret.rotation.y = Math.PI / 16

  main.add(tabouret)

  //sonette

  sonette = new THREE.Group();
  sonette.name = "sonette"

  cloche = new THREE.Mesh(
   new THREE.SphereGeometry(.025, 32, 16, 0, Math.PI),
   gold
  );

  cloche.name = "cloche"
  objectsToTest.push(sonette)
  interObj.push(cloche)


  socle = new THREE.Mesh(
   new THREE.CylinderGeometry(.025, .028, .008, 32),
   brown
  );

  tige = new THREE.Mesh(
   new THREE.CylinderGeometry(.004, .004, .07, 32),
   gold
  );

  tige.position.y = -.005
  socle.position.y = -.005
  cloche.position.y = .0025

  sonette.position.y = .097
  sonette.position.x = .18
  sonette.position.z = .16

  cloche.rotation.x = -Math.PI / 2

  sonette.add(cloche)
  sonette.add(socle)
  sonette.add(tige)
  main.add(sonette)


  //lampe

  light = new THREE.Group();
  abaj = new THREE.Group();


  topLight = new THREE.Mesh(
   new THREE.SphereGeometry(.05, 32, 16, 0, Math.PI),
   red
  );
  topLightInside = new THREE.Mesh(
   new THREE.SphereGeometry(.05, 32, 16, 0, Math.PI),
   goldInside
  );



  topLight.rotation.y = -Math.PI / 16

  topLightInside.position.copy(topLight.position)
  topLightInside.rotation.copy(topLight.rotation)


  lightRing = new THREE.Mesh(
   new THREE.TorusGeometry(.045, .003, 12, 100, Math.PI),
   gold
  );
  lightRing.rotation.x = -Math.PI / 2
  lightRing.rotation.y = -Math.PI / 2
  lightRing.position.z = .01

  abaj.add(lightRing)
  abaj.add(topLight)
  abaj.add(topLightInside)


  abaj.scale.set(.8, 1.5, .8)

  abaj.rotation.x = -Math.PI / 2


  tigeLight = new THREE.Mesh(
   new THREE.CylinderGeometry(.004, .004, .1, 32),
   gold
  );

  socleLight = new THREE.Mesh(
   new THREE.CylinderGeometry(.04, .04, .015, 32),
   gold
  );
  socleLight.position.y = -.005


  socleLight2 = new THREE.Mesh(
   new THREE.CylinderGeometry(.005, .04, .005, 32),
   gold
  );
  socleLight2.position.y = .005

  socleLight3 = new THREE.Mesh(
   new THREE.CylinderGeometry(.01, .006, .025, 32),
   gold
  );
  socleLight3.position.y = .016

  socleLight4 = new THREE.Mesh(
   new THREE.CylinderGeometry(.004, .01, .005, 32),
   goldInverse
  );
  socleLight4.position.y = .031


  abaj.position.y = .12
  tigeLight.position.y = .04

  light.add(socleLight)
  light.add(socleLight2)
  light.add(socleLight3)
  light.add(socleLight4)
  light.add(tigeLight)
  light.add(abaj)
  main.add(light)

  light.position.y = .098
  light.position.x = .15
  light.position.z = .3

  light.rotation.y = Math.PI / 4



  //telephone


  const shape = new THREE.Shape();
  shape.moveTo(0, 0);
  shape.lineTo(0, .3);
  shape.lineTo(.125, .3);
  shape.lineTo(.075, 0);
  shape.lineTo(0, 0);

  const extrudeSettings = {
   steps: 1,
   depth: .01,
   bevelEnabled: true,
   bevelThickness: 0,
   bevelSize: 0,
   bevelOffset: 0,
   bevelSegments: 0
  };

  const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

  const phoneBoxLeft = new THREE.Mesh(geometry, goldInverse);
  phoneBoxLeft.rotation.y = Math.PI / 2
  phoneBoxLeft.position.x = .02
  phoneBoxLeft.position.y = .08
  phoneBoxLeft.position.z = -.24


  const phoneBoxRight = new THREE.Mesh(geometry, goldInverse);
  phoneBoxRight.rotation.y = Math.PI / 2
  phoneBoxRight.position.x = -.18
  phoneBoxRight.position.y = .08
  phoneBoxRight.position.z = -.24

  phoneBox.add(phoneBoxRight);
  phoneBox.add(phoneBoxLeft);


  phoneBoxBack = new THREE.Mesh(
   new THREE.BoxGeometry(.2, .3, .01),
   goldInverse
  );
  phoneBoxBack.position.x = -.08
  phoneBoxBack.position.y = .23
  phoneBoxBack.position.z = -.245

  phoneBox.add(phoneBoxBack)

  phoneBoxTop = new THREE.Mesh(
   new THREE.BoxGeometry(.2, .01, .125),
   goldInverse
  );
  phoneBoxTop.position.x = -.08
  phoneBoxTop.position.y = .375
  phoneBoxTop.position.z = -.305

  phoneBox.add(phoneBoxTop)


  let GLBloader = new GLTFLoader();

  GLBloader.load('objects/phone2.gltf', function(gltf) {
   gltf.scene.traverse(function(child) {

    if (child.isMesh && child.name.substr(0, 3) == "RED") {
     child.material = red
    }

    if (child.isMesh && child.name.substr(0, 3) == "GOL") {
     child.material = goldInverse
    }

    if (child.isMesh && child.name.substr(0, 4) == "BLAC") {
     child.material = brown
    }

    if (child.isMesh && child.name.substr(0, 4) == "BLAN") {
     child.material = white
    }
    child.name = "phone"
   })

   phone = gltf.scene
   gltf.scene.name = "phone"


   main.add(gltf.scene);
   phone.scale.set(.0008, .0008, .0008)

   phone.position.z = -.28;
   phone.position.y = .18;
   phone.position.x = -.075;
   phone.rotation.y = Math.PI / 2;
  })

  main.add(phoneBox)
  phoneBox.position.y = -.07



  //mini coffret
  if(!onlyRose){
  main.add(customBox);
  }

  mini = new THREE.Mesh(
   new THREE.BoxGeometry(box.width, box.height, box.width),
   [
    side1,
    side2,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/front2.jpg') }),
    backSide
   ]
  );

  customBox.scale.set(.0833, .0833, .0833)
  customBox.position.x = .14
  customBox.position.y = .17
  customBox.position.z = -.135
  customBox.rotation.y = .9

  customBox.add(mini)
  mini.name = "custom"

  objectsToTest.push(mini)
  interObj.push(mini)

if(!onlyBrut){
  main.add(customBoxPink);
}




  miniPink = new THREE.Mesh(
   new THREE.BoxGeometry(box.width, box.height, box.width),
   [
    side1Pink,
    side2Pink,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/frontPinkPlain.jpg') }),
    backSide
   ]
  );

  customBoxPink.add(miniPink)
  miniPink.name = "custom_pink"

  customBoxPink.scale.set(.0833, .0833, .0833)
  customBoxPink.position.x = .14
  customBoxPink.position.y = .17
  customBoxPink.position.z = -.27
  customBoxPink.rotation.y = .9


  objectsToTest.push(miniPink)
  interObj.push(miniPink)


  /// autres

  miniline = { x: .18, y: -.2 };

  mini2 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), [
    side1Pink,
    side2Pink,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/faces/charlotte.jpg')  }),
    backSide
   ]
   
  );

  mini2.position.x = miniline.x
  mini2.position.y = miniline.y
  mini2.position.z = -.07
  main.add(mini2)
  mini2.rotation.y = Math.PI / 2
  mini2.name = onlyBrut ? "custom" : "custom_pink"
  objectsToTest.push(mini2)

  mini3 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12),
   coffretMatPink
  );

  mini3.position.x = miniline.x
  mini3.position.y = miniline.y
  mini3.position.z = -.12
  mini3.name =  onlyBrut ? "custom" : "custom_pink"
  objectsToTest.push(mini3)
  main.add(mini3)

  mini4 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), [
    side1Pink,
    side2Pink,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/faces/diala.jpg')  }),
    backSide
   ]
  );

  mini4.position.x = miniline.x
  mini4.position.y = miniline.y
  mini4.position.z = -.17
  mini4.name =  onlyBrut ? "custom" : "custom_pink"
  objectsToTest.push(mini4)
  main.add(mini4)
  mini4.rotation.y = Math.PI / 2

  mini5 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12),
   coffretMatPink
  );

  mini5.position.x = miniline.x
  mini5.position.y = miniline.y
  mini5.position.z = -.22
  mini5.name =  onlyBrut ? "custom" : "custom_pink"
  objectsToTest.push(mini5)
  main.add(mini5)

  mini6 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), [
    side1Pink,
    side2Pink,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/faces/leo.jpg')  }),
    backSide
   ]
  );

  mini6.position.x = miniline.x
  mini6.position.y = miniline.y
  mini6.position.z = -.27
  mini6.name =  onlyBrut ? "custom" : "custom_pink"
  objectsToTest.push(mini6)
  main.add(mini6)
  mini6.rotation.y = Math.PI / 2

  mini7 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), [
    side1Pink,
    side2Pink,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: mantraTexturesPink[mantra_list[0]]  }),
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/faces/emma.jpg')   }),
   ]
  );

  mini7.position.x = miniline.x
  mini7.position.y = miniline.y
  mini7.position.z = -.32
  mini7.name =  onlyBrut ? "custom" : "custom_pink"
  objectsToTest.push(mini7)
  main.add(mini7)

  mini8 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), 
   coffretMatPink
  );

  mini8.position.x = miniline.x - .06
  mini8.position.y = miniline.y
  mini8.position.z = -.32
  main.add(mini8)
  mini8.name = onlyBrut ? "custom" : "custom_pink"
  objectsToTest.push(mini8)
  mini8.rotation.y = Math.PI / 2

  minilinetop = { x: .18, y: -.03 };

  miniTop2 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), [
    side1,
    side2,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/faces/thomas.jpg') }),
    backSide
   ]
  );

  miniTop2.position.x = minilinetop.x
  miniTop2.position.y = minilinetop.y
  miniTop2.position.z = -.07
  miniTop2.name =  onlyRose ? "custom_pink" : "custom"
  objectsToTest.push(miniTop2)
  main.add(miniTop2)


  miniTop3 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), [
    side1,
    side2,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/faces/pauline.jpg') }),
    backSide
   ]
  );

  miniTop3.position.x = minilinetop.x
  miniTop3.position.y = minilinetop.y
  miniTop3.position.z = -.12
  main.add(miniTop3)
  miniTop3.rotation.y = Math.PI / 2
  miniTop3.name =  onlyRose ? "custom_pink" : "custom"
  objectsToTest.push(miniTop3)

  miniTop4 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12),
   coffretMat
  );

  miniTop4.position.x = minilinetop.x
  miniTop4.position.y = minilinetop.y
  miniTop4.position.z = -.17
  main.add(miniTop4)
  miniTop4.name =  onlyRose ? "custom_pink" : "custom"
  objectsToTest.push(miniTop4)


  miniTop5 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), [
    side1,
    side2,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/faces/jb.jpg') }),
    backSide
   ]
  );

  miniTop5.position.x = minilinetop.x
  miniTop5.position.y = minilinetop.y
  miniTop5.position.z = -.22
  main.add(miniTop5)
  miniTop5.rotation.y = Math.PI / 2
  miniTop5.name =  onlyRose ? "custom_pink" : "custom"
  objectsToTest.push(miniTop5)

  miniTop6 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12),
   coffretMat
  );

  miniTop6.position.x = minilinetop.x
  miniTop6.position.y = minilinetop.y
  miniTop6.position.z = -.27
  main.add(miniTop6)
  miniTop6.name =  onlyRose ? "custom_pink" : "custom"
  objectsToTest.push(miniTop6)

  miniTop7 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), [
    side1,
    side2,
    bottomSide,
    topSide,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/faces/florent.jpg') }),
    backSide
   ]
  );

  miniTop7.position.x = minilinetop.x
  miniTop7.position.y = minilinetop.y
  miniTop7.position.z = -.32
  main.add(miniTop7)
  miniTop7.rotation.y = Math.PI / 2
  miniTop7.name =  onlyRose ? "custom_pink" : "custom"
  objectsToTest.push(miniTop7)

  miniTop8 = new THREE.Mesh(
   new THREE.BoxGeometry(box.width / 12, box.height / 12, box.width / 12), [
    side1,
    side2,
    bottomSide,
    topSide,
    front,
    new THREE.MeshBasicMaterial({ map: textureLoader.load('textures/faces/vincent.jpg') }),
   ]
  );

  miniTop8.position.x = minilinetop.x - .06
  miniTop8.position.y = minilinetop.y
  miniTop8.position.z = -.32
  main.add(miniTop8)
  miniTop8.name =  onlyRose ? "custom_pink" : "custom"
  objectsToTest.push(miniTop8)

  //chariot


  main.add(trolley)

  GLBloader.load('objects/chariot.gltf', function(gltf) {
   gltf.scene.traverse(function(child) {


    //console.log(child.name)

    if (child.isMesh && child.name.substr(0, 3) == "ROU") {
     child.material = red
    }

    if (child.isMesh && child.name.substr(0, 2) == "OR") {
     child.material = gold
    }

    if (child.isMesh && child.name.substr(0, 4) == "NOIR") {
     child.material = brown
    }

    if (child.isMesh && child.name.substr(0, 4) == "PNEU") {
     child.material = brown
    }


    if (child.isMesh && child.name.substr(0, 4) == "ARGE") {
     child.material = gold
    }

   })

   chariot = gltf.scene

   trolley.add(gltf.scene);
   chariot.scale.set(.001, .001, .001)
  })


  trolley.position.z = .67;
  trolley.position.x = .05
  trolley.position.y = -box.width / 2 + .065;
  trolley.rotation.y = Math.PI / 7



  GLBloader.load('objects/composition2.gltf', function(gltf) {
   gltf.scene.traverse(function(child) {



    if (child.isMesh && child.name.substr(0, 3) == "ROU") {
     child.material = red
    }

    if (child.isMesh && child.name.substr(0, 2) == "JA") {
     child.material = gold
    }

    if (child.isMesh && child.name.substr(0, 4) == "NOIR") {
     child.material = brown
    }

    if (child.isMesh && child.name.substr(0, 4) == "PNEU") {
     child.material = brown
    }


    if (child.isMesh && child.name.substr(0, 4) == "BLAN") {
     child.material = white
    }

    child.name = "custom"

   })

   compo = gltf.scene
   compo.rotation.y = -Math.PI / 2 + .02
   compo.position.y = .003;

   objectsToTest.push(compo)

   trolley.add(gltf.scene);
   compo.scale.set(.008, .008, .008)
  })


  // bouteilles

  GLBloader.load('objects/bottle4.gltf', function(gltf) {

   gltf.scene.traverse(function(child) {

    if (child.isMesh && child.name.substr(0, 3) == "RUB") {
     //child.material = black
    }

    if (child.isMesh && child.name.substr(0, 2) == "SC") {
     child.material = red
    }

    if (child.isMesh && child.name.substr(0, 4) == "VERR") {
     child.material = glass
    }

    if (child.isMesh && child.name.substr(0, 4) == "DORE") {
     child.material = gold
    }


    if (child.isMesh && child.name.substr(0, 4) == "ETIQ") {
     child.material = etiquette
    }

    child.name = "custom"
   })

   bottle = gltf.scene

   if(!onlyRose){
   main.add(gltf.scene);
   }

   bottle.scale.set(.0006, .0006, .0006)

   bottle.position.x = .18
   bottle.position.y = .168
   bottle.position.z = -.08

   bottle.rotation.y = Math.PI / 2 - .3
   bottle.name = "custom"
   objectsToTest.push(bottle)

  })


  GLBloader.load('objects/bottle4.gltf', function(gltf) {

    gltf.scene.traverse(function(child) {
 
     if (child.isMesh && child.name.substr(0, 3) == "RUB") {
      //child.material = black
     }
 
     if (child.isMesh && child.name.substr(0, 2) == "SC") {
      child.material = gold
     }
 
     if (child.isMesh && child.name.substr(0, 4) == "VERR") {
      child.material = glass
     }
 
     if (child.isMesh && child.name.substr(0, 4) == "DORE") {
      child.material = pink
     }
 
 
     if (child.isMesh && child.name.substr(0, 4) == "ETIQ") {
      child.material = etiquettePink
     }
 
     child.name = "custom_pink"
    })
 
    bottlePink = gltf.scene
 

    if(!onlyBrut){
    main.add(gltf.scene);
    }

    bottlePink.scale.set(.0006, .0006, .0006)
 
    bottlePink.position.x = .18
    bottlePink.position.y = .168
    bottlePink.position.z = -.215
 
    bottlePink.rotation.y = Math.PI / 2 -.3
    bottlePink.name = "custom"
    objectsToTest.push(bottlePink)
 
   })
 

  // lights

  miniLight1 = new THREE.Group();

  socleLight = new THREE.Mesh(
   new THREE.CylinderGeometry(.018, .022, .02, 16),
   gold
  );
  mainLight = new THREE.Mesh(
   new THREE.CylinderGeometry(.008, .008, .1, 16),
   gold
  );

  mainLight.rotation.z = Math.PI / 2
  mainLight.position.z = .015
  mainLight.scale.x = .7

  socleLight.rotation.x = Math.PI / 2

  miniLight1.add(mainLight)
  miniLight1.add(socleLight)

  //main.add(miniLight1)

  miniLight1.position.z = box.height / 2
  miniLight1.position.x = -box.width / 2 + .05
  miniLight1.position.y = box.width


  miniLight2 = new THREE.Group();

  socleLight2 = new THREE.Mesh(
   new THREE.CylinderGeometry(.018, .022, .02, 16),
   gold
  );
  mainLight2 = new THREE.Mesh(
   new THREE.CylinderGeometry(.008, .008, .12, 16),
   gold
  );
  mainLight2.rotation.z = Math.PI / 2
  mainLight2.position.z = .015
  mainLight2.scale.x = .7

  socleLight2.rotation.x = Math.PI / 2


  miniLight2.add(mainLight2)
  miniLight2.add(socleLight2)

  //main.add(miniLight2)

  miniLight2.position.z = -box.height / 2
  miniLight2.position.x = -box.width / 2 + .05
  miniLight2.position.y = box.width
  miniLight2.rotation.y = Math.PI


  //////////////// DEBUG
  /*
  var objDebug = tamp

  gui.add(objDebug.position, "x", -2, 2, .001).onChange(objDebug.position.updateNormal);
  gui.add(objDebug.position, "y", -2, 2, .001).onChange(objDebug.position.updateNormal);
  gui.add(objDebug.position, "z", -2, 2, .001).onChange(objDebug.position.updateNormal);

  gui.add(objDebug.rotation, "x", -Math.PI, Math.PI, .1).onChange(objDebug.rotation.updateNormal);
  gui.add(objDebug.rotation, "y", -Math.PI, Math.PI, .1).onChange(objDebug.rotation.updateNormal);
  gui.add(objDebug.rotation, "z", -Math.PI, Math.PI, .1).onChange(objDebug.rotation.updateNormal);

  gui.close();
*/
 }


 /**
  * animate
  */

 function animate() {
  requestAnimationFrame(animate);

  //controls.update();

  /////////////////
  //show hover star info
  /*
    camera.updateMatrixWorld();
    camera.matrixWorldInverse.getInverse(camera.matrixWorld);
    cameraViewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    //frustum.setFromProjectionMatrix(cameraViewProjectionMatrix);
  */

  raycaster.setFromCamera(mouse, camera)
  const intersects = raycaster.intersectObjects(objectsToTest, true)

  tick++;

  //console.log(intersects.length)

  if (step != "intro" && !isMobile) {
   cursor.classList.remove('active')
  }


  if (intersects.length > 0 && (step == "ready" || step == "opening2")) {

   /**
    * interactions
    */

   intersects.forEach(obj => {

    if (obj.object.name == "door" && tick > 1) {
     obj.object.angle = Math.PI / 2;
     nHover = obj.object.number
    }

    //console.log(obj.object.name)

    if (obj.object.name == "elevator" && tick > 1 && stepLift != "liftOpen") {
     lTime = 0
     stepLift = "liftOpen";
     elevatorSound.currentTime = 0;
     elevatorSound.play()
    }

    if (obj.object.name == "custom") {
     interact = "custom"

     if (!isMobile) {
      cursor.innerHTML = language.customise_brut
     }
     cursor.classList.add('active')
    }

    if (obj.object.name == "custom_pink") {
      interact = "custom_pink"
 
      if (!isMobile) {
       cursor.innerHTML = language.customise_rose
      }
      cursor.classList.add('active')
     }


    if (obj.object.name == "cloche") {
     interact = "cloche"
     if (!isMobile) {
      cursor.innerHTML = language.ring
     }
     cursor.classList.add('active')
    }

   })

   document.body.classList.add('hover')

//console.log('interact',interact)

  }
  else {
   interact = ""
   document.body.classList.remove('hover')
  }


  /**
   * doors
   */

  doors.forEach((door, index) => {
   if (index != nHover) {
    door.angle = door.angleStart;
   }
   door.rotation.y = lerp(door.rotation.y, door.angle, .03)
   numbers[index].rotation.y = lerp(door.rotation.y, door.angle, .03)
  })




  /**
   * elevator
   */

  if (stepLift == "liftOpen") {

   if (lTime < .1) {
    btnTop.material = red;
    btnBottom.material = red;
   }
   else {
    btnTop.material = white;
    btnBottom.material = white;
   }

   if (lTime > .2) {
    lTime = 0
    stepLift = "liftClose"

   }

   lTime += .001;
   lTime = clamp(lTime, 0, 1)

   p = lerp(p, -.01, lTime)
   r = lerp(r, .73, lTime)
   l = lerp(l, .17, lTime)

   liftDoorR.position.x = -box.width / 2 + p
   liftDoorR.position.z = box.height / 2 - r

   liftDoorL.position.x = -box.width / 2 + p
   liftDoorL.position.z = box.height / 2 - l

  }

  if (stepLift == "liftClose") {

   lTime += .001;
   lTime = clamp(lTime, 0, 1)

   p = lerp(p, .015, lTime)
   r = lerp(r, .55, lTime)
   l = lerp(l, .35, lTime)

   liftDoorR.position.x = -box.width / 2 + p
   liftDoorR.position.z = box.height / 2 - r

   liftDoorL.position.x = -box.width / 2 + p
   liftDoorL.position.z = box.height / 2 - l

  }



  /**
   * intro
   */

  if (step == "opening") {
   oTime = lerp(oTime, 1.2, .015);
   oTime = clamp(oTime, 0, 2)
  }


  if (step == "opening2") {
   speed = lerp(speed, .02, .01)
   oTime = lerp(oTime, 4, speed);
   oTime = clamp(oTime, 0, 4);

   if (!document.querySelector('.mhd-cta-push-brut').classList.contains("active")) {
    document.querySelector('.mhd-cta-push-brut').classList.add('active')
    document.querySelector('.mhd-cta-push-rose').classList.add('active')
   }

   if (oTime > 3.9) {
    step = "ready"
   }
  }



  if (oTime >= 1.1 && step == "opening") {

   face2.material = mt_f2_after;
   face3.material = mt_f3_after;
   face4.material = mt_f4_after;
   face4c.scale.y = .31
   lTime = 0
   step = "opening2"
   stepLift = "liftOpen"

   elevatorSound.currentTime = 0;
   elevatorSound.play()
   cursor.classList.add('active')
  }


  groomBox.material.opacity = range(0, .25, 1, 0, oTime)

  groomInside.position.y = range(0, 1, posGroom - .15, posGroom, oTime)
  etageres.position.y = range(.25, 1, -.3, 0, oTime)
  elevator.position.y = range(.25, 1, -.3, 0, oTime)
  phoneBox.position.y = range(.0, 1, -.07, 0, oTime)
  planeWhite.position.y = range(.85, 1, -box.width / 2, -box.width / 2 - .065, oTime)


  face1.rotation.y = range(0, 1, 0, angleSide, oTime)
  face4.rotation.y = range(0, .75, 0, -Math.PI * .5, oTime)
  face5.rotation.x = range(0, 1, 0, -angleSide, oTime)
  face6.rotation.x = range(0, 1, 0, angleSide, oTime)

  plane.position.y = range(0, 1, -box.width / 2, -.38, oTime)

  trolley.position.x = range(2.5, 4, -0.54, .05, oTime)
  trolley.position.z = range(3.25, 4, 0.58, .67, oTime)
  trolley.rotation.y = range(3, 4, Math.PI / 2, Math.PI / 7, oTime)

  //console.log(step, oTime)

  /**
   * custom
   */
  if (step == "custom") {
   cTime = lerp(cTime, 1, .06)
  }


  if (step == "customBack") {

   bTime = lerp(bTime, .1, .02)
   cTime = lerp(cTime, 0, bTime)


   //console.log(bTime, cTime)

   if (cTime < .01) {
    step = "ready"
   }
  }


  if (step == "custom" || step == "customBack") {




target=colorBox=="brut" ? customBox : customBoxPink;
var targetZ=colorBox=="brut" ? -.135 : -.27 ;

/*
   camCustomX = range(0, 1, endX, .2, cTime)
   camCustomY = range(0, 1, endY, mini.position.y, cTime)
*/

   if (isMobile) {
    target.position.x = range(0, 1, .14, 2.1, cTime)
    target.position.z = range(0, 1, targetZ, 0, cTime)
    target.position.y = range(0, 1, .17, .29, cTime)
    near = range(0, 1, 8, .5, cTime)
    far = range(0, 1, 10, 2, cTime)
   }
   else {
    target.position.x = range(0, 1, .14, 2.55, cTime)
    target.position.z = range(0, 1, targetZ, .07, cTime)
    target.position.y = range(0, 1, .17, .195, cTime)
    near = range(0, 1, 8, .5, cTime)
    far = range(0, 1, 10, 2, cTime)
    main.rotation.y = lerp(main.rotation.y, 0, .1);
   }

   scene.fog = new THREE.Fog(0xffffff, near, far);

  }
/*

  /**
   * camera
   */
  var camX = range(0, 1, beginX, endX, oTime)
  var camY = range(0, 1, beginY, endY, oTime)
  var lookYfirst=isMobile ? .9 : .4;
  var lookY = range(0, 1,lookYfirst,  .1, oTime)



  camera.position.set(camX, camY, 0);
  camera.lookAt(0, lookY, 0)

  if (!isMobile && !touchDevice) {

   camera.lookAt(0, lookY, 0)

   if (step == "custom" || step == "cart" || step == "end") {

    main.rotation.y = lerp(main.rotation.y, 0, .005);
    scene.rotation.z = lerp(scene.rotation.z, 0, .03);

    angleY = mouse.x + Math.PI / 2.3
    target.rotation.y = lerp(target.rotation.y, angleY, .03);

    angleX = -mouse.y / 2
    target.rotation.z = lerp(target.rotation.z, angleX, .03);
    target.rotation.x = lerp(target.rotation.x, -angleX, .03);
   }
   else if (step == "intro") {
    angleY = clamp(-mouse.x * 2, -.3, .3)
    h = mouse.y
    main.rotation.y = lerp(main.rotation.y, angleY, .01);

    angleX = -mouse.y / 40
    scene.rotation.z = lerp(scene.rotation.z, angleX, .03);

   }
   else if (step == "opening" || step == "opening2" || step == "ready") {
    angleY = clamp(-mouse.x * 4, -.7, .7)
    h = mouse.y
    main.rotation.y = lerp(main.rotation.y, angleY, .005);


    angleX = -mouse.y / 10
    scene.rotation.z = lerp(scene.rotation.z, angleX, .03);

   }
   else if (step == "customBack") {
    target.rotation.y = lerp(target.rotation.y, .9, .03);
    target.rotation.z = lerp(target.rotation.z, 0, .03);
    target.rotation.x = lerp(target.rotation.x, 0, .03);

    angleY = clamp(-mouse.x * 2, -.5, .5)
    h = mouse.y
    main.rotation.y = lerp(main.rotation.y, angleY, .01);
   }
  }
  else {
   ////////mobile

   if (step == "opening" || step == "opening2" || step == "ready") {
    angleY = clamp(angleY, -Math.PI / 4, Math.PI / 4)


    /*
    if (angleY >= 0 ) {
     cursor.innerHTML = language.customise_brut;
     btnType = 0
    }else{
      cursor.innerHTML = language.customise_rose;
      btnType = 1
    }
    */

    main.rotation.y = lerp(main.rotation.y, angleY, .05);

   }
   else if (step == "intro") {
    angleY = clamp(angleY, -Math.PI / 6, Math.PI / 6)
    main.rotation.y = lerp(main.rotation.y, angleY, .1);
   }
   else if (step == "custom" || step == "cart" || step == "end") {
    main.rotation.y = lerp(main.rotation.y, 0, .2);

    angleYc = clamp(angleYc, 0, Math.PI)
    target.rotation.y = lerp(target.rotation.y, angleYc, .05);
   }



  }

  //console.log(step)



  groomInside.lookAt(camera.position.x, 0, camera.position.z)
  groomBox.lookAt(camera.position.x, 0, camera.position.z)


  translateX = mouse.x * sizes.width * 0.5
  translateY = -mouse.y * sizes.height * 0.5 - 50

  if (!isMobile) {
   cursor.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
  }
  renderer.render(scene, camera);


  if ((step == "ready" || step == "opening2")) {


   exp.classList.add('interaction');

   interObj.forEach((point, index) => {
    var screenPosition = new THREE.Vector3();
    point.getWorldPosition(screenPosition)
    screenPosition.project(camera)

    var translateX = 0;
    var translateY = 0;

    var targetPosition = new THREE.Vector3();
    targetPosition = targetPosition.setFromMatrixPosition(point.matrixWorld);

    var lookAt = new THREE.Vector3();
    camera.getWorldDirection(lookAt);
    var cameraPos = new THREE.Vector3().setFromMatrixPosition(camera.matrixWorld);
    var pos = targetPosition.sub(cameraPos);


    translateX = screenPosition.x * sizes.width * 0.5
    translateY = -screenPosition.y * sizes.height * 0.5 - 5


    document.querySelector('.cursor-' + point.name).style.transform = `translateX(${translateX}px) translateY(${translateY}px)`

   })
  }
  else {
   exp.classList.remove('interaction');
  }

 }



 /**
  * mouse
  */
var decal;

 const initMouse = () => {

  decal = document.querySelector('canvas').getBoundingClientRect().top;

  document.querySelector('.mhd-exp').addEventListener(moveEvent, (event) => {
   iks = desktop ? event.clientX : event.touches[0].clientX
   igrek = desktop ? event.clientY : event.touches[0].clientY

   igrek -= window.scrollY

   mouse.x = iks / sizes.width * 2 - 1
   mouse.y = -(igrek / sizes.height) * 2 + 1

  })



  document.querySelector('.mhd-exp').addEventListener(startEvent, (event) => {

   if (isMobile) {
    iks = event.touches[0].clientX
    igrek = event.touches[0].clientY

    igrek -= window.scrollY

    mouse.x = iks / sizes.width * 2 - 1
    mouse.y = -(igrek / sizes.height) * 2 + 1
   }
  })

 }

 const open = () => {
  if (step == "intro") {


   step = "opening";
   document.querySelector('.mhd-start').classList.add('hide');
   document.querySelector('.mhd-bkg').classList.remove('hover');
   document.querySelector('.mhd-exp').classList.remove('ready');

   document.querySelector('.mhd-bkg').removeEventListener("click", open, true);
   document.querySelector('.mhd-groom').classList.add('visible');

   document.querySelector('.groom-chat-first').classList.add('visible');

   setTimeout(() => {
    document.querySelector('.groom-chat-first').classList.remove('visible');
   }, 7000)

  }
 }

 document.querySelector('.mhd-btn-start').addEventListener('click', () => {
  open();
 })
 document.querySelector('.mhd-start').addEventListener('mouseenter', () => {
  cursor.classList.remove('active')
 })

 document.querySelector('canvas').addEventListener('mouseenter', () => {
  if (step == "intro" && !isMobile) {
   cursor.classList.add('active')
  }
 })

 // click canvas
/*
 cursor.addEventListener('click', () => {

  if (btnType == 0) {
    colorBox="brut"
    openCustom("inter");
  }


  if (btnType == 1) {
    colorBox="rose"
    openCustom("inter");
  }

 })
*/

 document.querySelector('canvas').addEventListener('click', () => {

console.log(interact)

  if (interact == "custom") {
   if (step != "custom") {
    colorBox="brut"
    
    openCustom("inter");
   }
  }

  if (interact == "custom_pink") {
    if (step != "custom") {
      colorBox="rose"
     openCustom("inter");
    }
   }



 })


 document.querySelector('.mhd-perso').addEventListener('click', () => {
  if (!isMobile) {
    closeChat()
  }
 })

 document.querySelector('.mhd-cart').addEventListener('click', () => {
  closeChat()
 })
 document.querySelector('.mhd-end').addEventListener('click', () => {
  closeChat()
 })

 document.querySelector('.cursor-cloche').addEventListener('click', () => {
  openChat();
 })

 document.querySelector('.cursor-custom').addEventListener('click', () => {
  if (step != "custom") {
    colorBox="brut"
    openCustom("inter");
  }
 })

 document.querySelector('.cursor-custom_pink').addEventListener('click', () => {
  if (step != "custom") {
    colorBox="rose"
   openCustom("inter");
  }
 })



 //add_text('debug')

 
 

 const closeCoffret = () => {
  step = "closing";
 }

 document.querySelector('.begin ').classList.remove('begin');
 setTimeout(() => {
  document.querySelector('.mhd-preloader').classList.add('visible');
 }, 100)

 document.querySelector('.mhd-back').addEventListener('click', () => {

  if (step == "custom") {
   closeCustom();
  }

  if (step == "cart") {
   changeToCustom("inter");
  }

  if (step == "end") {
   changeToCart();
  }

  closeChat();

 })


 document.querySelector('.mhd-cta-push-brut').addEventListener('click', () => {
   colorBox="brut"
  openCustom();
 })


 document.querySelector('.mhd-cta-push-rose').addEventListener('click', () => {
  colorBox="rose"
 openCustom();
})


document.querySelector('.btn-more').addEventListener('click', () => {
   closeCustom();
  });


  document.querySelector('.btn-lp').addEventListener('click', () => {

    if(market=="#int"||market=="#usa"){
      window.open('https://www.moet.com/en-ca/specially-yours')
    }

    if(market=="#cn"){
      window.open('https://www.moet.com/zh-hans/specially-yours')
    }

    if(market=="#fr"){
      window.open('https://www.moet.com/fr-ca/specially-yours')
    }
  
    if(market=="#ko"){
      window.open('https://www.moet.com/kr-kr/specially-yours')
    }



   });

 document.querySelector('.mhd-btn-cart').addEventListener('click', () => {
 window.open('https://www.millesima.fr/champagne-moet-chandon-brut-imperial-0000.html')
 })


 document.querySelector('.custom-again').addEventListener('click', () => {
  input.value = ""
  changeToCustom("inter");
 })



 const openCustom = (from) => {
   if(step!='intro'){
  step = "custom"
  document.querySelector('#mhd-name').value = "";
  document.querySelector('.mhd-perso').classList.add('visible');
  document.querySelector('.mhd-back').classList.remove('hide');
  document.querySelector('.groom-chat-first').classList.remove('visible');
  document.querySelector('.mhd-cart').classList.remove('visible');
  document.querySelector('.mhd-end').classList.remove('visible');

  document.querySelector('.mhd-cta-push-brut').classList.remove('active')
  document.querySelector('.mhd-cta-push-rose').classList.remove('active')

  cursor.classList.remove('active')

  if (isMobile) {
   document.querySelector('.mhd-sound ').classList.remove('visible')
  }

  if(from=="inter"){
    closeChat();
  }

if(from=="groom"){
  document.querySelector('.mhd-perso').classList.remove('visible');
  document.querySelector('.mhd-cart').classList.add('visible');
  document.querySelector('.mhd-end').classList.remove('visible');
  document.querySelector('.mhd-groom').classList.add('end');
}

}
 }


 const closeCustom = () => {
  bTime = 0
  step = "customBack"
  document.querySelector('.mhd-perso').classList.remove('visible');
  document.querySelector('.mhd-cart').classList.remove('visible');
  document.querySelector('.mhd-back').classList.add('hide');

  document.querySelector('.mhd-cta-push-brut').classList.add('active')
  document.querySelector('.mhd-cta-push-rose').classList.add('active')

  // document.querySelector('.select-bottom').classList.remove('open')
  document.querySelector('.mhd-groom').classList.add('visible');

  if (isMobile) {
   cursor.classList.add('active')
  }
 }

/**
  * text
  */

var textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
let textBrut = {};
let textPink = {};

function add_text_brut(nom, small) {

  fontLoader.load("/fonts/matrix3.json", function (font) {

    var text2D = new TextGeometry(nom.toUpperCase(), {
      size: small ? .1 : .145,//.145
      height: .1,
      curveSegments: 12,
      font: font,
      bevelEnabled: true,
      bevelThickness: .0015,
      bevelSize: .001,
      bevelOffset: 0,
      bevelSegments: 3
    });

    var text = new THREE.Mesh(text2D, textMaterial);

    var box = new THREE.Box3().setFromObject(text);

    text.rotation.z = Math.PI / 2;
    text.name = "3Dtext"

    var boxSize = new THREE.Vector3();
    box.getSize(boxSize);

    text.position.x = .07;
    text.position.z = .226;
    text.position.y = -boxSize.x / 2;

    textReady = true;
    customBox.add(text);
    textBrut = text
  })

}

function add_text_pink(nom, small) {

  fontLoader.load("/fonts/matrix3.json", function (font) {

    var text2D = new TextGeometry(nom.toUpperCase(), {
      size: small ? .1 : .145,//.145
      height: .1,
      curveSegments: 12,
      font: font,
      bevelEnabled: true,
      bevelThickness: .0015,
      bevelSize: .001,
      bevelOffset: 0,
      bevelSegments: 3
    });


    var textRose = new THREE.Mesh(text2D, textMaterial);

    var box = new THREE.Box3().setFromObject(textRose);

    textRose.rotation.z = Math.PI / 2;
    textRose.name = "3Dtext"

    var boxSize = new THREE.Vector3();
    box.getSize(boxSize);

    textRose.position.x = .07;
    textRose.position.z = .226;
    textRose.position.y = -boxSize.x / 2;

    textReadyPink = true;
    customBoxPink.add(textRose);
    textPink = textRose
  })
}

export const new_name = (name) => {
    if(colorBox == 'brut') {
      customBox.remove(textBrut);
      add_text_brut(name);
    }
    if(colorBox == 'rose') {
      customBoxPink.remove(textPink);
      add_text_pink(name);
    }
 }

 if(market == '#fr') {
  add_text_brut('Personnalisez-moi', true);
  add_text_pink('Personnalisez-moi', true);
 } else {
  add_text_brut('Make it yours', true);
  add_text_pink('Make it yours', true);
 }


//checkname
function isNumeric(value) {
  return value.match(/^\d+((\/{1}|\-{1})\d+)*$/);
}

const checkList = (name) => {

  if (isNumeric(name)) {
    return true
  }

  var result = nameList.filter(function (e) {
    var text1 = e.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    var text2 = name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return text1 == text2
  })

  return result.length > 0
}


// name validation (to be added)
const mhdValidate = document.querySelector('.mhd-btn-valid');
const errorMsg = document.querySelector('.mhd-error');

document.querySelector('#mhd-name').addEventListener('input', () => {

  document.querySelector('#mhd-name').classList.remove('error');
  errorMsg.classList.remove('active');
  var remain = 14 - document.querySelector('#mhd-name').value.length;
  var remainText = '';
  (market != '#fr') ? remainText = ' characters left' : remainText = ' caractères restants';
  if (remain > 1) {
    document.querySelector('.mhd-undertext').innerHTML = remain + remainText;
  }
  else {
    document.querySelector('.mhd-undertext').innerHTML = remain + remainText;
  }
})

mhdValidate.addEventListener('click', e => {
  e.preventDefault();

  var name = document.querySelector('#mhd-name').value;
  
  // if (checkList(name)) {
    // prenom ok
    document.querySelector('#mhd-name').value=name;
    new_name(name)
    // document.querySelector('#custom-text').innerHTML='Personnalisation : '+name;
    // document.querySelector('.mhdc-form-step1').classList.remove('active')
    // document.querySelector('.mhdc-form-step2').classList.add('active')
    changeToCart()
  // } else {
    //prenom non valide
    // errorMsg.classList.add('active');
    // document.querySelector('#mhd-name').classList.add('error')
  // }

})

 const changeToCart = () => {

  if(colorBox=="brut"){
    document.querySelector('.mhd-cart h2').innerHTML=language.brut_name;
    document.querySelector('.mhd-cart .perso-text').innerHTML=language.brut_description;  
  }

  if(colorBox=="rose"){
    document.querySelector('.mhd-cart h2').innerHTML=language.rose_name;
    document.querySelector('.mhd-cart .perso-text').innerHTML=language.rose_description;  
  }

  document.querySelector('.mhd-perso').classList.remove('visible');
  document.querySelector('.mhd-cart').classList.add('visible');
  document.querySelector('.mhd-end').classList.remove('visible');
  document.querySelector('.mhd-groom').classList.add('end');
  step = "cart"
 }

 const changeToCustom = (from) => {
  document.querySelector('.mhd-perso').classList.add('visible');
  document.querySelector('.mhd-cart').classList.remove('visible');
  document.querySelector('.mhd-end').classList.remove('visible');
  document.querySelector('.mhd-groom').classList.remove('end');

  document.querySelector('.mhd-back').classList.remove('hide');
  document.querySelector('.groom-chat-first').classList.remove('visible');

  step = "custom"


  cursor.classList.remove('active')
 }

 const changeToEnd = () => {
  document.querySelector('.mhd-end').classList.add('visible');
  document.querySelector('.mhd-cart').classList.remove('visible');
  document.querySelector('.mhd-groom').classList.add('end');
  step = "end"
 }



 window.addEventListener('touchstart', function(e) {
  memoX = e.changedTouches[0].pageX;


  memoAngle = main.rotation.y
  if (step == "custom" || step == "cart" || step == "end") {
   memoAngleC = customBox.rotation.y
  }

 })

 window.addEventListener('touchmove', function(e) {


  if (!chat.classList.contains('visible')) {
   newAngle = (e.changedTouches[0].pageX - memoX) / 100
   angleY = memoAngle + newAngle;

   if (step == "custom" || step == "cart" || step == "end") {
    newAngleC = (e.changedTouches[0].pageX - memoX) / 100
    angleYc = memoAngleC + newAngleC;

   }
  }

 })


//  document.querySelector('.select-top').addEventListener('click',()=>{
//    if( document.querySelector('.select-bottom').classList.contains('open')){
//     document.querySelector('.select-bottom').classList.remove('open')
//     document.querySelector('.mhd-groom').classList.add('visible');
//    }else{
//     document.querySelector('.select-bottom').classList.add('open')
//     if(isMobile){
//       document.querySelector('.mhd-groom').classList.remove('visible');
//     }

//    }
//  })

// var mantras= document.querySelectorAll('.mantra-item');
// var mantraTexture;

// mantras.forEach(mantra=>{

// if(mantra.dataset.num==firstMantra){
//   mantra.classList.add('active')
//   document.querySelector('.select-top span').innerHTML=mantra.innerHTML;
// }

//   mantra.addEventListener('click',(e)=>{
//     document.querySelector('.select-bottom').classList.remove('open')
//     document.querySelector('.select-top span').innerHTML=e.currentTarget.innerHTML;
//     document.querySelector('.mhd-groom').classList.add('visible');
//     document.querySelector('.mantra-item.active').classList.remove('active')
//     e.currentTarget.classList.add('active')

//     mini.material=   [
//       side1,
//       side2,
//       bottomSide,
//       topSide,
//       new THREE.MeshBasicMaterial({ map: mantraTextures[parseInt(e.currentTarget.dataset.num)] }),
//       backSide
//      ]

//      miniPink.material=[
//       side1Pink,
//       side2Pink,
//       bottomSide,
//       topSide,
//       new THREE.MeshBasicMaterial({ map: mantraTexturesPink[parseInt(e.currentTarget.dataset.num)]  }),
//       backSide
//      ]

//   })
// })


// document.querySelector('.mhd-btn-valid').addEventListener('click', (event) => {
//   changeToCart();
// })

// GROOM  BOT

const btnClose = document.querySelector('.close-groom');
const groom = document.querySelector('.groom-img');
const chat = document.querySelector('.groom-chat');
const closeNotif = document.querySelector('.close-new');
const dialog = document.querySelector('.groom-chat-bottom');

const bellSoundGroom = new Audio('sounds/bell.mp3')


 const openChat = () => {
    chat.classList.add('visible')
    closeNotif.classList.add('visible')
    document.querySelector('.groom-chat-first').classList.remove('visible');
    bellSoundGroom.currentTime = 0;
    bellSoundGroom.play()
}


 const closeChat = () => {
    chat.classList.remove('visible')
    closeNotif.classList.remove('visible')
}

groom.addEventListener('click', (e) => {
    if (chat.classList.contains('visible')) {
        closeChat()
    }
    else {
        openChat()
    }
})

btnClose.addEventListener('click', (e) => {
    closeChat()
})


const getInfo = () => {
    const d = new Date();
    let hour = d.getHours() >= 10 ? d.getHours() : "0" + d.getHours();
    let minutes = d.getMinutes() >= 10 ? d.getMinutes() : "0" + d.getMinutes();

    let dateLocale = d.toLocaleString(langDate, {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
    });

    let time = dateLocale + ' - ' + hour + "<span>:</span>" + minutes;
    let infos = '<div class="dialog-info"><div class="groom-id"></div> <div class="dialog-time">' + time + '</div></div>'

    return infos;
}

const addMsg = (text, text2, text3) => {

    var question = '<div class="groom-question">' + text + '</div>';

    if (text2) {
        question += '<div class="groom-question">' + text2 + '</div>';
    }

    if (text3) {
        question += '<div class="groom-question">' + text3 + '</div>';
    }

    dialog.innerHTML += '<div class="groom-dialog">' + question + getInfo() + '</div>'
}

const addImg = (src) => {
    var img = '<img class="dialog-img" src="/img/' + src + '">';
    dialog.innerHTML += '<div class="groom-dialog">' + img + getInfo() + '</div>'
}


const addLink = (href)=>{
  var text=`<div class="groom-question"><a class="dialog-link" target="_blank" href="${href}">${href}</a></div>`
  dialog.innerHTML += '<div class="groom-dialog">' + text + getInfo() + '</div>'
}

const addSelect=(msg)=>{
    var choices=msg.content;
    var reponses="";

    choices.forEach((choice,index)=>{
        reponses += `<div class="groom-reponse active" groom-menu=${msg.choices[index]} >${choice}</div>`;
    })

    dialog.innerHTML += '<div class="groom-dialog dialog-right">' + reponses + getInfo() + '</div>'
}



const nextMessage=(nMessage)=>{

    //console.log("message",nMessage,messages[nMessage].next)

    if(messages[nMessage].type=="text"){
        addMsg(messages[nMessage].content[0], messages[nMessage].content[1],messages[nMessage].content[2]);
        nextMessage(messages[nMessage].next)
    }

    if(messages[nMessage].type=="link"){
      addLink(messages[nMessage].content[0]);
      nextMessage(messages[nMessage].next)
  }

    if(messages[nMessage].type=="img"){
        addImg(messages[nMessage].content[0]);
        nextMessage(messages[nMessage].next)
    }

    if(messages[nMessage].type=="menu"){
        addSelect(messages[nMessage]);
    }

    if(messages[nMessage].type=="golink"){
      window.open(messages[nMessage].content[0])
      nextMessage(messages[nMessage].next)
  }

    if(messages[nMessage].type=="special"){

       if(messages[nMessage].content=="open_brut"){
        colorBox="brut"
        //openCustom("groom");
       }

       if(messages[nMessage].content=="open_rose"){
        colorBox="rose"
        //openCustom("groom");
       }

       if(messages[nMessage].content[0]=="mantra"){

        //console.log('mantra',messages[nMessage].content[1])
        openCustom("groom");

        if(document.querySelector('.mantra-item.active')){
         document.querySelector('.mantra-item.active').classList.remove('active')
        }
        document.querySelector('.mantra-item[data-num="'+messages[nMessage].content[1]+'"').classList.add('active')
        document.querySelector('.select-top span').innerHTML=document.querySelector('.mantra-item[data-num="'+messages[nMessage].content[1]+'"').innerHTML;


        mini.material=   [
          side1,
          side2,
          bottomSide,
          topSide,
          new THREE.MeshBasicMaterial({ map: mantraTextures[messages[nMessage].content[1]] }),
          backSide
         ]
    
         miniPink.material=[
          side1Pink,
          side2Pink,
          bottomSide,
          topSide,
          new THREE.MeshBasicMaterial({ map: mantraTexturesPink[messages[nMessage].content[1]]  }),
          backSide
         ]
       }

        nextMessage(messages[nMessage].next)
    }
    
}


dialog.addEventListener('click', (e) => {
    
    if (chat.classList.contains('visible')) {

        //menu
        if (e.target.classList.contains('groom-reponse') && e.target.getAttribute('groom-menu')) {

            if (document.querySelector('.last-choice')){
                document.querySelector('.last-choice').classList.remove('last-choice')
            } 

            let nMenu = e.target.getAttribute('groom-menu');

            e.target.parentNode.querySelectorAll('.groom-reponse').forEach(rep => {

                if (rep.getAttribute('groom-menu') != nMenu) {
                    rep.remove();
                }
                else {
                    rep.classList.remove('active')
                    rep.classList.add('last-choice')
                }

            })

            nextMessage(nMenu);

            var goto = document.querySelector('.last-choice').parentNode.offsetTop;
            dialog.scrollTo({ top: goto, behavior: 'smooth' });
        }
    }
})



////// START
init(); 

setTimeout(() => {
    nextMessage(0);
}, 600)

