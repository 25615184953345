
export const languageCN = {
    open_box:'打开礼盒',
    intro_text: `您好，欢迎来到酩悦香槟礼品店<br/>我将全程协助您完成定制流程。`,
    start_exp: "开始体验",
    customise:"定制",
    customise_brut:"定制",
    customise_rose:"定制",
    bot_start:`请随时点击铃铛，我很乐意为您提供协助。`,
    back:'返回',
    customise_title:'定制您的礼盒',
    customise_content:`请从下面列表中选择一个祝福语。所有定制产品均不予退换。`,
    mantra_content:  `请从下面列表中选择一个祝福语。所有定制产品均不予退换。`,
    character_left:'14 characters left',
    customise_placeholder:'Enter your customisation here',
    confirm:'确认',
    ring:"点击铃铛",
    buy:`购买`,
    box_title:`定制礼盒`,
    composition:`含亚硫酸盐。酒精度数：12°`,
    brut_name:`酩悦粉红香槟`,
    brut_description:`自1869年以来，酩悦皇室香槟一直是酒庄的标志性香槟，象征着酩悦一贯独特的风格。`,
    rose_name:`酩悦粉红香槟`,
    rose_description:`浪漫绚丽的酩悦粉红香槟，象征着酩悦香槟一贯的优雅魅力。自1743年面世以来，酩悦香槟一直为各种庆祝的场合和时刻打造香槟。`,
    again:`重新开始体验`,
    landing_page:`探索更多定制礼盒`
}

export const messagesCN=[
    {id:0,type:"text",content:["您好，欢迎来到酩悦香槟礼品店。", "请问您有何需要？"],next:1},
    {id:1,type:"menu",content:[
         "我想定制一套礼盒",
         "我想知道关于此礼盒更多的信息。",
         "和我说说酩悦香槟的风格吧。",
         "请告诉我酩悦粉红香槟的品鉴概要。",
         "酩悦粉红香槟可以搭配哪些菜肴？"],
         choices:[23,6,8,18,20]},
    {id:2,type:"text",content:["With pleasure ! Do you want to choose: "],next:3},  
    {id:3,type:"menu",content:["Moët Brut Impérial","Moët Rosé Impérial"],choices:[22,23]},
    {id:4,type:"text",content:["好的！您想输入什么信息？","请在下方选择您的信息。 ↓"],next:5},  
    {id:5,type:"menu",content:[
         "SAY YES TO LOVE",
         "YOU ARE MY FAVORITE PERSON",
         "SPECIALLY YOURS",
         "无可替代 ",
         "有你真好",
         "藏不住的喜欢 ",
        ],
         choices:[37,39,38,49,50,51]},
    {id:6,type:"img",content:['Moet-Mantra2.jpeg'],next:7},  
    {id:7,type:"text",content:[`您可选择心仪的爱意表白内容及对应的酩悦粉红香槟定制礼盒，为您的爱人送上一份惊喜。（在确认订单时，请确认您所选的定制内容无误，所有定制产品均不支持退换。）。`,`您还有其他需要吗？`],next:1}, 
    {id:8,type:"text",content:[`愉悦的果香，诱人的口感和优雅的成熟度点亮了每瓶酩悦香槟。从葡萄种植到精工细酿，每一步都追求卓越，成就具有传奇品质的酩悦香槟。`],next:9}, 
    {id:9,type:"img",content:['moet_visuel.jpg'],next:10},  
    {id:10,type:"text",content:[`您想了解酩悦系列的其他产品吗？`],next:11},  
    {id:11,type:"menu",content:["是的，我想了解一下。","不用了，谢谢。"],choices:[12,13]},
    {id:12,type:"golink",content:["https://www.moet.com/zh-hans/our-champagnes-moet-chandon"],next:13}, 
    {id:13,type:"text",content:[`您还有其他需要吗？`],next:1},  
    {id:14,type:"img",content:['moet_visuel3.jpg'],next:15},  
    {id:15,type:"text",content:[`A sparkling nose: Green apple, citrus fruit… The captivating intensity of these fruity notes is immediately followed by a wave of mineral freshness and white flowers. The blonde notes of brioche, cereals and fresh walnuts then announce an elegant maturity.`,`A palate blending sumptuousness and subtlety: The sumptuous, delicious notes of white-fleshed fruits like pear, white peach and apple burst on the palate with light and fine bubbles. The soft vivacity of nuances of citrus fruit then gradually emerge, delicately lingering in the mouth.`],next:13},  
    {id:16,type:"img",content:['moet_visuel2.jpg'],next:17},  
    {id:17,type:"text",content:[`Accompany your Moët & Chandon Champagne with raw foods (sushi, carpaccio), fresh fruit, oysters or dishes cooked with spices such as turmeric or curry.`],next:13},  
    {id:18,type:"img",content:['moet_rose_visuel1.jpg'],next:19},  
    {id:19,type:"text",content:[`香气馥郁鲜甜，草莓、覆盆子和樱桃等新鲜红果甜香，混合些许微妙的玫瑰芬芳，余味略带一些淡淡的胡椒辛香，浓郁鲜活，令人沉醉。`,`融合浓烈和柔滑的丰盈口感，既有红果的醇厚多汁、桃果的饱满紧致，亦有薄荷的微妙清新，余味中略带辛香。`],next:13}, 
    {id:20,type:"img",content:['moet_rose_visuel2.jpg'],next:21},  
    {id:21,type:"text",content:[`酩悦粉红香槟可与烤制的贝类、鱼类、口味强烈而多油的食品，浸制的、煎烤的各种红色肉类或禽类搭配享用。亦可配以熟的蔬菜、红色的鲜果以增加口味和色彩。`],next:13},  
    {id:22,type:"special",content:['open_brut'],next:4},  
    {id:23,type:"special",content:['open_rose'],next:4},     
    {id:24,type:"text",content:['这是否满足您的要求？'],next:25}, 
    {id:25,type:"menu",content:['是的，很棒',`不完全是。我想选择另一条信息`],choices:[26,27]},
    {id:26,type:"text",content:[`太好了。`,`请问您还有何需要？`],next:28}, 
    {id:27,type:"text",content:['您还想包含什么其他信息？'],next:5},  
    {id:28,type:"menu",content:[
      `我还能更改信息吗？`,
      `我想了解礼盒定制的更多信息。`
    ],choices:[27,32]},
    {id:29,type:"text",content:[`请在这里购买礼盒：`],next:34}, 
    {id:30,type:"text",content:[`我们让电商合作伙伴全权负责送货。请直接与他们联系。`],next:33}, 
    {id:31,type:"text",content:[`这是我们的联系表`],next:35}, 
    {id:32,type:"text",content:[`请查看我们的“特别为您定制”（Specially Yours）产品 : `],next:36}, 
    {id:33,type:"text",content:[`您还有其他需要吗？`],next:28}, 
    {id:34,type:"link",content:[`https://www.millesima.fr/champagne-moet-chandon-brut-imperial-0000.html`],next:33},  
    {id:35,type:"link",content:[`https://www.moet.com/zh-hans/lianxiwomen`],next:33},  
    {id:36,type:"link",content:[`https://www.moet.com/zh-hans/specially-yours`],next:33},  
    {id:37,type:"special",content:['mantra',10],next:24},   
    {id:38,type:"special",content:['mantra',11],next:24},  
    {id:39,type:"special",content:['mantra',12],next:24},  
    {id:40,type:"special",content:['mantra',13],next:24}, 
    {id:41,type:"special",content:['mantra',14],next:24},   
    {id:42,type:"special",content:['mantra',15],next:24},  
    {id:43,type:"special",content:['mantra',0],next:24},  
    {id:44,type:"special",content:['mantra',1],next:24}, 
    {id:45,type:"special",content:['mantra',2],next:24},   
    {id:46,type:"special",content:['mantra',3],next:24},  
    {id:47,type:"special",content:['mantra',4],next:24},  
    {id:48,type:"special",content:['mantra',5],next:24}, 
    {id:49,type:"special",content:['mantra',6],next:24}, 
    {id:50,type:"special",content:['mantra',7],next:24}, 
    {id:51,type:"special",content:['mantra',8],next:24}, 
    {id:52,type:"special",content:['mantra',9],next:24}, 

];